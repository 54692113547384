import { http } from '../http'
import { DriverRoute, DriverRouteUpsertRequest } from '../../models/route/DriverRoute'
import useSWR, { SWRResponse } from 'swr'
import { PageResponse } from '../../models/Page'
import { usePageable } from '../../utils/swr_utils'

export const driverRouteCreate = (request: DriverRouteUpsertRequest): Promise<DriverRoute> => {
  return http.postData<DriverRouteUpsertRequest, DriverRoute>('/driver/routes', request)
}

export const useDriverRoute = (routeId: string): SWRResponse<DriverRoute> => {
  return useSWR<DriverRoute>([`/driver/routes/${routeId}`])
}

export const useDriverRouteCurrent = (): SWRResponse<DriverRoute | undefined> => {
  return useSWR<DriverRoute | undefined>([`/driver/routes/current`])
}

export const useDriverRoutes = (page?: number, pageSize?: number): SWRResponse<PageResponse<DriverRoute>> => {
  return usePageable('/driver/routes/search', { page: page, page_size: pageSize })
}

export const driverRouteUpdate = (routeId: string, request: DriverRouteUpsertRequest): Promise<DriverRoute> => {
  return http.putData<DriverRouteUpsertRequest, DriverRoute>(`/driver/routes/${routeId}`, request)
}

export const driverRouteDelete = (routeId: string): Promise<any> => {
  return http.delete(`/driver/routes/${routeId}`)
}
