import './style.css'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LOGIN__PATH, MAIN__PATH } from '../../../config/page_paths'
import { authOtpRequest } from '../../../api/auth/auth_api'
import { AuthCodeRequest } from '../../../models/auth/Auth'
import { useUser } from '../../Layout/Layout'
import { useDebugState } from 'use-named-state'

const OTP_LENGTH = 6

export default function AuthOtpPage() {
  const { mutateUser } = useUser()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const urlCode = searchParams.get('code')

  const [code, setCode] = useDebugState<string | undefined>('code', undefined)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useDebugState<boolean>('submitButtonDisabled', true)

  if (!code && urlCode !== null) {
    setCode(urlCode)
    setSubmitButtonDisabled(false)
  }

  const handleSubmitOtp = () => {
    const email = localStorage.getItem('email')
    authOtpRequest({ email: email, code: code } as AuthCodeRequest)
      .then((response) => {
        const token = response.token
        if (token) {
          localStorage.setItem('token', token)
          mutateUser && mutateUser()
          navigate(MAIN__PATH)
        } else {
          navigate(LOGIN__PATH)
        }
      })
      .catch(e => {
        console.log('AuthOtpView.handleSubmitOtp: Error: ' + JSON.stringify(e))
        navigate(MAIN__PATH)
      })
  }

  const handleOtpChange = (value: string) => {
    setCode(value)
    if (value.length === OTP_LENGTH) {
      setSubmitButtonDisabled(false)
    } else {
      setSubmitButtonDisabled(true)
    }
  }

  return <div className='auth_otp_page'>
    <Typography variant='subtitle1' component='p' sx={{ textAlign: 'center' }}>Enter One-Time Password to sign in</Typography>
    <MuiOtpInput TextFieldsProps={{ type: 'tel' }} value={code} onChange={handleOtpChange} length={OTP_LENGTH} />
    <Button variant='contained' color='primary' disabled={submitButtonDisabled} onClick={handleSubmitOtp}>Sign In</Button>
  </div>
}
