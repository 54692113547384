import './style.css'
import Button from '@mui/material/Button'
import { Google } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LOGIN_WITH_OTP__PATH } from '../../../config/page_paths'
import { getBackendUrl, redirectToUrl } from '../../../config/env'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { useDebugState } from 'use-named-state'
import { AuthRequest } from '../../../models/auth/Auth'
import { authRequest } from '../../../api/auth/auth_api'

export default function LoginPage() {
  const navigate = useNavigate()

  const [email, setEmail] = useDebugState<string | undefined>('email', undefined)
  const [authButtonDisabled, setAuthButtonDisabled] = useDebugState<boolean>('authButtonDisabled', true)

  const handleOnEmailChange = (value: string) => {
    setEmail(value)
    if (value !== '') {
      setAuthButtonDisabled(false)
      localStorage.setItem('email', value)
    } else {
      setAuthButtonDisabled(true)
      localStorage.setItem('email', value)
    }
  }

  const handleAuthRequest = (event: any) => {
    event.preventDefault()
    if (!authButtonDisabled) {
      authRequest({ 'email': email } as AuthRequest)
        .then((_) => navigate(LOGIN_WITH_OTP__PATH))
    } else {
      console.log('Auth button should be disabled. Set email first')
    }
  }

  return <div className='login_page'>
    <Button size={'large'} variant='contained' color='primary' href={getBackendUrl() + '/login?redirect_to=' + redirectToUrl()} startIcon={<Google />}>Sign in with Google</Button>
    <Divider style={{width:'100%'}} flexItem={true} className='login_page__divider'>OR</Divider>
    <Typography variant='subtitle1'>Enter email to receive a One-Time Password</Typography>
    <TextField label='Email' variant='outlined' onChange={e => handleOnEmailChange(e.target.value)} value={email} InputLabelProps={{ shrink: true }} className='login_page__email' />
    <Button size={'large'} variant='contained' color='primary' disabled={authButtonDisabled} onClick={handleAuthRequest} className='login_page__otp_button'>Request One-Time Password</Button>
  </div>
}
