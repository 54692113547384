import './style.css'
import { CircularProgress } from '@mui/material'
import { useDebugState } from 'use-named-state'
import { useEffect } from 'react'
import { useUser } from '../../../../pages/Layout/Layout'
import { UserUpdateRequest } from '../../../../models/user/User'
import { meUpdate } from '../../../../api/user/user_api'
import Button_ from '../../../elements/Button/Button'
import UserCardPaymentInfo from '../../card_info/UserCardPaymentInfo/UserCardPaymentInfo'
import UserCardBasicInfo from '../../card_info/UserCardBasicInfo/UserCardBasicInfo'
import UserCardUserInfo from '../../card_info/UserCardUserInfo/UserCardUserInfo'
import { UserRole } from '../../../../models/user/UserRole'
import { fromUiUserRole, toUiUserRole } from '../../../../utils/user_role_utils'

export default function UserProfile() {
  const { user, mutateUser } = useUser()
  const [name, setName] = useDebugState<string | undefined>('name', undefined)
  const [phoneNumber, setPhoneNumber] = useDebugState<string | undefined>('phoneNumber', undefined)
  const [selectedRole, setSelectedRole] = useDebugState<string | undefined>('selectedRole', undefined)

  const isChanged = () => {
    return user?.name !== name
      || user?.phone_number !== phoneNumber
      || toUiUserRole(user?.selected_role) !== selectedRole
  }

  useEffect(() => {
    if (user) {
      setName(user.name)
      setPhoneNumber(user.phone_number)
      setSelectedRole(toUiUserRole(user.selected_role))
    }
  }, [user])

  const handleSaveClick = () => {
    const request = {
      name: name,
      phone_number: phoneNumber,
      selected_role: fromUiUserRole(selectedRole),
    } as UserUpdateRequest
    return meUpdate(request)
      .then(_ => mutateUser && mutateUser())
  }

  return <div className='user_profile'>
    {user ? <div className='user_profile__fields'>
        <UserCardUserInfo user={user} name={name} setName={setName} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
        {user.role === UserRole.ADMIN &&
            <UserCardPaymentInfo user={user} />
        }
        <UserCardBasicInfo user={user} selectedRole={selectedRole} setSelectedRole={setSelectedRole} />

        <Button_ label='Save' disabled={!isChanged()} onClick={handleSaveClick} logPath='UserProfile.handleSaveClick.updateMe' className='user_profile__save' />
      </div>
      : <CircularProgress />
    }
  </div>
}
