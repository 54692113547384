import './style.css'
import { DRIVER__AGREEMENTS__PATH, DRIVER__DASHBOARD__PATH, DRIVER__ORDERS__PATH, DRIVER__ROUTES__PATH, DRIVER__STRIPE__PATH } from '../../../config/page_paths'
import Tab_ from '../../elements/Tab/Tab/Tab'

export const DriverTabConfig = {
  'Dashboard': { 'label': 'Dashboard', 'index': 0 },
  'Orders': { 'label': 'My Orders', 'index': 1 },
  'Routes': { 'label': 'My Routes', 'index': 2 },
  'Stripe': { 'label': 'Stripe', 'index': 3 },
  'Agreements': { 'label': 'Agreements', 'index': 4 },
}

interface DriverTabProps {
  tabIndex: number
}

export default function DriverTab({ tabIndex }: DriverTabProps) {
  return <div className='driver_tab'>
    <Tab_
      activeIndex={tabIndex}
      tabConfigs={[
        { label: DriverTabConfig.Dashboard.label, navigateTo: DRIVER__DASHBOARD__PATH },
        { label: DriverTabConfig.Orders.label, navigateTo: DRIVER__ORDERS__PATH },
        { label: DriverTabConfig.Routes.label, navigateTo: DRIVER__ROUTES__PATH },
        { label: DriverTabConfig.Stripe.label, navigateTo: DRIVER__STRIPE__PATH },
        { label: DriverTabConfig.Agreements.label, navigateTo: DRIVER__AGREEMENTS__PATH },
      ]} />
  </div>
}
