import './style.css'
import { Typography } from '@material-ui/core'

export default function HelpPage() {
  return <div className='help_page'>
    <Typography className='help_page__description'>
      If you have any questions, please, email us: <a href='mailto:help@iujex.com'>help@iujex.com</a>
    </Typography>
  </div>
}
