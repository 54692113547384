import './style.css'
import Text_ from '../../../elements/./Text/Text'
import OrderTimeline from '../../OrderTimeline/OrderTimeline'
import { useOrderTrack } from '../../../../api/order/order_api'
import { packagesToString } from '../../../../utils/package_utils'
import Divider_ from '../../../elements/Divider/Divider'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import { Typography } from '@mui/material'

interface UserOrderTrackProps {
  orderId: string
}

export default function OrderTrackCard({ orderId }: UserOrderTrackProps) {
  const { data: order } = useOrderTrack(orderId)
  return <div className='order_track_card'>
    {order ? <div className='order_track_card__fields'>
        <Divider_ text='Timeline' className='order_track_card__divider__timeline' />
        <OrderTimeline orderStatus={order.status} />

        <Divider_ text='Short info' className='order_track_card__divider__short_info' />
        <Text_ label='Pickup from' value={order.pickup_from_short.short_address} className='order_track_card__pickup_from_short' />
        <Text_ label='Pickup at' value={userTimeZoneDate(order.pickup_at_calculated)} className='order_track_card__pickup_at_calculated' />
        <Text_ label='Delivery to' value={order.delivery_to_short.short_address} className='order_track_card__delivery_to_short' />
        <Text_ label='Delivery at' value={userTimeZoneDate(order.delivery_at_calculated)} className='order_track_card__delivery_at_calculated' />
        <Text_ label='Packages' value={packagesToString(order.packages)} className='order_track_card__packages' />
        <Text_ label='Order ID' value={order.order_id} className='order_track_card__order_id' />
      </div>
      : <>
        <Typography>{`Order '${orderId}' not found`}</Typography>
      </>
    }
  </div>
}

