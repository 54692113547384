import {RefObject} from "react";

export function scrollToElement(previousRefs: RefObject<HTMLDivElement>[], ms = 700) {
  if (previousRefs.length === 0) {
    return
  }
  const HEADER_OFFSET = 10
  let yCoordinate = HEADER_OFFSET
  let last = 0
  if (previousRefs.length > 0) {
    previousRefs.forEach(ref => {
      const height = ref.current?.getBoundingClientRect()?.height || 0
      yCoordinate += height
      last = height
    })
  }

  let startTime: number | null = null
  const animateScroll = (currentTime: number) => {
    if (startTime === null) {
      startTime = currentTime
    }
    const elapsedTime = currentTime - startTime
    const scrollProgress = Math.min(elapsedTime / ms, 1)
    const easeInOutCubic = (t: number) => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    const scrollTo = yCoordinate - last + last * easeInOutCubic(scrollProgress)

    window.scrollTo({top: scrollTo})

    if (elapsedTime < ms) {
      requestAnimationFrame(animateScroll)
    } else {
      startTime = null
    }
  }

  requestAnimationFrame(animateScroll)
}