import { Alert, Snackbar } from '@material-ui/core'
import { AlertColor } from '@material-ui/core/Alert/Alert'

const DEFAULT_HIDDEN_SECONDS = 5

export type SnackbarVariant = 'outlined' | 'standard' | 'filled'

interface SnackbarProps {
  message?: string
  setMessage: (value?: string) => void
  color?: AlertColor
  variant?: SnackbarVariant
  hiddenSeconds?: number
}

export default function Snackbar_({ message, setMessage, color, variant, hiddenSeconds }: SnackbarProps) {

  function cleanUp() {
    setMessage(undefined)
  }

  return <>
    <Snackbar open={message !== undefined} autoHideDuration={(hiddenSeconds || DEFAULT_HIDDEN_SECONDS) * 1000} onClose={cleanUp}>
      <Alert onClose={cleanUp} severity={color || 'error'} variant={variant}>{message}</Alert>
    </Snackbar>
  </>
}
