import './style.css'
import { GoogleMap, InfoWindowF, MarkerF, PolygonF, PolylineF, useLoadScript } from '@react-google-maps/api'
import { getGoogleApiKey } from '../../../config/env'
import { LatLng } from 'react-google-places-autocomplete/build/types'
import { useDebugState } from 'use-named-state'
import { ReactNode } from 'react'
import { decode } from 'google-polyline'
import { LineColor, PolylineData } from './PolylineData'
import { Marker } from './Marker'
import { PolygonColor, PolygonData } from './PolygonData'


export interface SelectedMarker {
  markerId: string
  entityId: string
}

interface DecodedPolylineData {
  decodedPolyline: LatLng[]
  lineColor: LineColor
}

interface DecodedPolygonData {
  decodedPolygon: LatLng[]
  polygonColor: PolygonColor
}

interface Props {
  polygons?: PolygonData[]
  polylines?: PolylineData[]
  markers?: Marker[]
  renderMarkerWindow?: (marker: Marker) => ReactNode
  className: string
  selectedMarker?: SelectedMarker
  setSelectedMarker?: (value?: SelectedMarker) => void
  width?: string
}


export default function Map_(
  {
    polygons, polylines, markers,
    renderMarkerWindow,
    className,
    selectedMarker, setSelectedMarker,
    width
  }: Props) {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: getGoogleApiKey() || '', libraries: ['places'] })

  const [mapRef, setMapRef] = useDebugState<google.maps.Map | undefined>('mapRef', undefined)

  const mapStyle = { width: width || '400px', height: '100%' }
  const mapZoom = 5

  function decodePolyline(encoded: string): LatLng[] {
    return decode(encoded).map((latLng: any) => ({ lat: latLng[0], lng: latLng[1] } as LatLng))
  }

  const decodedPolylinesData: DecodedPolylineData[] = polylines
    ? polylines?.map((polylineData: PolylineData) => {
      return {
        decodedPolyline: decodePolyline(polylineData.encodedPolyline),
        lineColor: polylineData.lineColor
      } as DecodedPolylineData
    })
    : []

  const decodedPolygonsData: DecodedPolygonData[] = polygons
    ? polygons.map((polygonData: PolygonData) => {
      return {
        decodedPolygon: decodePolyline(polygonData.encodedPolygon),
        polygonColor: polygonData.polygonColor
      } as DecodedPolygonData
    })
    : []

  function fitOnMap(map: any): void {
    const bounds = new google.maps.LatLngBounds()
    decodedPolylinesData.forEach(polylineData =>
      polylineData.decodedPolyline.forEach((latLng: LatLng) => bounds.extend(latLng)))
    // decodedPolygonsData.forEach(polygonData =>
    //   polygonData.decodedPolygon.forEach((latLng: LatLng) => bounds.extend(latLng)))
    map.fitBounds(bounds)
    setMapRef(map)
  }

  function handleMarkerClick(marker: Marker): void {
    setSelectedMarker && setSelectedMarker({
        markerId: marker.markerId,
        entityId: marker.entityId,
      } as SelectedMarker
    )
    mapRef?.panTo({ lat: marker.lat, lng: marker.lng } as google.maps.LatLngLiteral)
  }

  return <>
    <div className={`map ${className}`}>
      {isLoaded
        ? <GoogleMap
          onLoad={fitOnMap}
          zoom={mapZoom}
          mapContainerStyle={mapStyle}
          // onClick={() => setSelectedMarker && setSelectedMarker(undefined)}
          options={{ gestureHandling: 'greedy' }}
        >
          {decodedPolygonsData.map((decodedPolygonData: DecodedPolygonData, i: number) =>
            <PolygonF path={decodedPolygonData.decodedPolygon} options={decodedPolygonData.polygonColor} key={i} />)}
          {decodedPolylinesData.map((decodedPolylineData: DecodedPolylineData, i: number) =>
            <PolylineF path={decodedPolylineData.decodedPolyline} options={decodedPolylineData.lineColor} key={i} />)}
          {markers && markers.map((marker: Marker, i: number) =>
            <MarkerF
              position={{ lat: marker.lat, lng: marker.lng } as LatLng}
              icon={marker.icon}
              label={marker.label}
              onClick={() => handleMarkerClick(marker)}
              key={i}
            >
              {selectedMarker && selectedMarker.markerId == marker.markerId &&
                  <InfoWindowF onCloseClick={() => setSelectedMarker && setSelectedMarker(undefined)}>
                    {renderMarkerWindow && renderMarkerWindow(marker)}
                  </InfoWindowF>
              }
            </MarkerF>
          )}
        </GoogleMap>
        : <span>Loading...</span>
      }
    </div>
  </>
}
