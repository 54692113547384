import {Typography} from "@material-ui/core";
import Button_ from "../../../elements/Button/Button";
import Title from "./Title";
import {MAIN__PATH} from "../../../../config/page_paths";

interface Props {
}

export default function UserOrderCreateV2_9_Done(props: Props) {
  return <div style={{display: "flex", flexDirection: "column",
    paddingTop: "0.25em", paddingBottom: "1.5em",
    // backgroundColor: "lightskyblue"
  }}>
    <Title text="You're all set!" className="user_order_create_v2__done__title"/>
    <Typography style={{margin: "auto", marginTop: "0.5em"}}>We're searching for the nearest driver to Deliver your package.</Typography>
    <Typography style={{margin: "auto", marginBottom: "2em"}}>You'll receive an update soon.</Typography>
    <div style={{margin: "auto"}}>
      <Button_ label={'Visit the Dashboard'} navigateTo={MAIN__PATH} />
    </div>
  </div>
}
