import useSWR, { SWRResponse } from 'swr'
import { OrderTrack } from '../../models/order/OrderTrack'
import { UserOrder, UserOrderCreateRequest } from '../../models/order/UserOrder'
import { http } from '../http'


export const orderCreate = (request: UserOrderCreateRequest, files?: FileList): Promise<UserOrder> => {
  return http.postMultipartData('/orders', request, files)
}

export const useOrderTrack = (orderId: string): SWRResponse<OrderTrack> => {
  return useSWR<OrderTrack>([`/orders/${orderId}/track`])
}
