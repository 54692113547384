import './style.css'
import { Document, Page, pdfjs } from 'react-pdf'
import { Container, Paper } from '@mui/material'
import { useState } from 'react'
import Button from '@mui/material/Button'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface PDFViewerProps {
  pdfUrl?: string
}

export default function PDFViewer({ pdfUrl }: PDFViewerProps) {
  const [showPdf, setShowPdf] = useState<boolean>(false)
  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [renderNavButtons, setRenderNavButtons] = useState<boolean>(false)

  const previousPage = () => {
    setPageNumber(prevPageNumber => prevPageNumber - 1)
  }
  const nextPage = () => {
    setPageNumber(prevPageNumber => prevPageNumber + 1)
  }

  function navigationButtons(className: string) {
    return <div className={className}>
      <Button disabled={pageNumber <= 1} onClick={previousPage}>Previous Page</Button>
      <Button disabled={pageNumber === numPages} onClick={nextPage}>Next Page</Button>
    </div>
  }

  return <div className='pdf_viewer'>
    {!showPdf
      ? <Button onClick={() => setShowPdf(true)}>Show PDF</Button>
      : <Button onClick={() => setShowPdf(false)}>Hide PDF</Button>
    }
    {
      showPdf && <>
        {renderNavButtons && navigationButtons('pdf_viewer__top_navigation')}
            <div>
                <Container className='pdf_container' maxWidth='md'>
                    <Paper elevation={3} className='pdf_document'>
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={({ numPages }) => {
                              setNumPages(numPages)
                              setRenderNavButtons(true)
                            }}
                            onError={e => console.log(e)}
                        >
                            <Page pageNumber={pageNumber} className='pdf_page' />
                        </Document>
                    </Paper>
                </Container>
            </div>
        {renderNavButtons && navigationButtons('pdf_viewer__bottom_navigation')}
        </>
    }
  </div>
}
