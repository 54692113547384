import Divider from '@mui/material/Divider'
import {SxProps} from "@mui/system";

interface DividerProps {
  text?: string
  className?: string
  sx?: SxProps
}

export default function Divider_({text, className, sx}: DividerProps) {
  return <>
    <Divider className={className} sx={sx}>
      {text && <b>{text}</b>}
    </Divider>
  </>
}
