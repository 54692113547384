import './style.css'
import Text_ from '../../../elements/./Text/Text'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import Divider_ from '../../../elements/Divider/Divider'
import { BaseAgreement } from '../../../../models/agreement/BaseAgreement'

interface AgreementCardDocumentInfoProps {
  agreement: BaseAgreement
  name?: string
  setName?: (_: string) => void
  number?: string
  setNumber?: (_: string) => void
}

export default function AgreementCardDocumentInfo({ agreement, name, setName, number, setNumber }: AgreementCardDocumentInfoProps) {
  return <>
    <Divider_ text='Document info' className='agreement_card__divider__document_info' />
    <Text_ label='Name' value={name} onChange={setName} className='agreement_card__name' />
    <Text_ label='Number' value={number} onChange={setNumber} className='agreement_card__number' />
    <Text_ label='Effective Date' value={userTimeZoneDate(agreement.effective_date)} className='agreement_card__effective_date' />
  </>
}
