import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { LOGIN__PATH, MAIN__PATH } from '../../config/page_paths'

export default function AuthRedirectPage() {
  const navigate = useNavigate()

  useEffect(() => {
    const token = Cookies.get('jwt_token')
    if (token) {
      localStorage.setItem('token', token)
      navigate(MAIN__PATH)
    } else {
      navigate(LOGIN__PATH)
    }
  }, [navigate])

  return <></>
}
