import './style.css'
import Text_ from '../../../elements/./Text/Text'
import { DriverOrder } from '../../../../models/order/DriverOrder'
import Divider_ from '../../../elements/Divider/Divider'

interface DriverOrderCardDriverInfoProps {
  order: DriverOrder
}

export default function DriverOrderCardDriverInfo({ order }: DriverOrderCardDriverInfoProps) {
  return <>
    <Divider_ text='Driver info' className='order_card__divider__driver_info' />
    <Text_ label='Driver rate' value={order.driver_rate} className='order_card__driver_rate' />
    <Text_ label='Internal comment' value={order.internal_comment} className='order_card__internal_comment' />
  </>
}
