import './style.css'
import Divider_ from '../../../elements/Divider/Divider'
import Text_ from '../../../elements/Text/Text'
import { toUiUserRole } from '../../../../utils/user_role_utils'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import { BaseUser } from '../../../../models/user/BaseUser'
import { UserRole } from '../../../../models/user/UserRole'
import UserCardSelectedRole from '../UserCardSelectedRole/UserCardSelectedRole'

interface UserCardBasicInfoProps {
  user: BaseUser
  selectedRole?: string
  setSelectedRole?: (value: string) => void
}

export default function UserCardBasicInfo({ user, selectedRole, setSelectedRole }: UserCardBasicInfoProps) {
  return <>
    <Divider_ text='Basic info' className='user_card__divider__basic_info' />
    <Text_ label='User ID' value={user.user_id} className='user_card__user_id' />
    <Text_ label='Role' value={toUiUserRole(user.role)} className='user_card__role' />
    {
      (user.role != UserRole.USER && setSelectedRole)
        ? <UserCardSelectedRole user={user} value={selectedRole} onChange={setSelectedRole} className='user_card__selected_role' />
        : <Text_ label='User selected Role' value={user.selected_role && toUiUserRole(user.selected_role)} className='user_card__selected_role' />
    }
    <Text_ label='Create at' value={userTimeZoneDate(user.created_at)} className='user_card__created_at' />
    <Text_ label='Updated at' value={userTimeZoneDate(user.updated_at)} className='user_card__updated_at' />
  </>
}
