import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getBackendUrl } from '../config/env'
import { ServerResponse } from '../models/ServerResponse'
import { Buffer } from 'buffer'
import { PAGE_403__PATH, PAGE_404__PATH } from '../config/page_paths'


class Http {

  private axiosInstance: AxiosInstance | null = null

  private get axios(): AxiosInstance {
    if (this.axiosInstance === null) {
      this.axiosInstance = axios.create({ baseURL: getBackendUrl() })
    }

    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token')
        if (token !== null) {
          config.headers.Authorization = 'Bearer ' + token
        }
        return config
      }, (error) => {
        return Promise.reject(error)
      }
    )

    this.axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 403) {
          window.location.href = PAGE_403__PATH
        } else if (error.response.status === 404) {
          window.location.href = PAGE_404__PATH
        } else {
          return Promise.reject(error)
        }
      }
    )

    return this.axiosInstance
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.get<T, R>(url, config)
  }

  getData<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.get<ServerResponse<T>>(url, config)
      .then(response => response.data.data)
  }

  getFile = (url: string): Promise<string | undefined> => {
    return http
      .get<string>(url, { responseType: 'arraybuffer' })
      .then((response) => Buffer.from(response.data, 'binary').toString('base64'))
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.post<T, R>(url, data, config)
  }

  postData<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.post<ServerResponse<R>>(url, data, config)
      .then(r => r.data.data)
  }

  postMultipartData<T = any, R = AxiosResponse<T>>(url: string, request: any, files?: FileList | null): Promise<R> {
    const formData = new FormData()
    formData.append('request', JSON.stringify(request))
    Array.from(files || []).forEach(file => formData.append('file', file))
    return this.axios.post<ServerResponse<R>>(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(r => r.data.data)
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.put<T, R>(url, data, config)
  }

  putData<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.put<ServerResponse<R>>(url, data, config)
      .then(r => r.data.data)
  }

  putMultipartData<T = any, R = AxiosResponse<T>>(url: string, request: any, files?: FileList | null): Promise<R> {
    const formData = new FormData()
    formData.append('request', JSON.stringify(request))
    Array.from(files  || []).forEach(file => formData.append('file', file))
    return this.axios.put<ServerResponse<R>>(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(r => r.data.data)
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.delete<T, R>(url, config)
  }

}

export const http = new Http()
