import useSWR, { SWRResponse } from 'swr'
import { PageRequest, PageResponse } from '../../models/Page'
import { usePageable } from '../../utils/swr_utils'
import { http } from '../http'
import { AdminAgreement, AdminAgreementCreateRequest, AdminAgreementFilter, AdminAgreementUpdateRequest } from '../../models/agreement/AdminAgreement'

export const adminAgreementCreate = (request: AdminAgreementCreateRequest, files: FileList): Promise<AdminAgreement> => {
  return http.postMultipartData('/admin/agreements', request, files)
}

export const useAdminAgreement = (agreementId: string): SWRResponse<AdminAgreement> => {
  return useSWR<AdminAgreement>([`/admin/agreements/${agreementId}`])
}

export async function adminAgreementDownload(agreementId: string): Promise<string | undefined> {
  return http.getFile(`/admin/agreements/${agreementId}`)
}

export const useAdminAgreements = (page?: number, pageSize?: number, filterRequest?: AdminAgreementFilter): SWRResponse<PageResponse<AdminAgreement>> => {
  const request = {
    page: page,
    page_size: pageSize,
    filter: filterRequest,
  } as PageRequest<AdminAgreementFilter>
  return usePageable('/admin/agreements/search', request)
}

export const adminAgreementSetActive = (agreementId: string): Promise<AdminAgreement> => {
  return http.putData<void, AdminAgreement>(`/admin/agreements/${agreementId}/active`)
}

export const adminAgreementSetInactive = (agreementId: string): Promise<AdminAgreement> => {
  return http.putData<void, AdminAgreement>(`/admin/agreements/${agreementId}/inactive`)
}

export const adminAgreementDelete = (agreementId: string): Promise<void> => {
  return http.delete(`/admin/agreements/${agreementId}`)
}

export const adminAgreementUpdate = (agreementId: string, request: AdminAgreementUpdateRequest): Promise<AdminAgreement> => {
  return http.putData<AdminAgreementUpdateRequest, AdminAgreement>(`/admin/agreements/${agreementId}`, request)
}
