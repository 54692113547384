import './style.css'
import Table_ from '../../elements/Table/Table'
import {ADMIN__RATES_CREATE__PATH} from '../../../config/page_paths'
import {useDebugState} from 'use-named-state'
import {toUiRateType} from '../../../utils/rate_type_utils'
import {RateType} from '../../../models/rate/AdminRate'
import {RATE_WIDTH} from '../../../config/table_witdth_config'
import {useAdminRates} from "../../../api/rate/admin_rate_api";

export default function AdminRatesTable() {
  const [page, setPage] = useDebugState('page', 0)
  const [pageSize, setPageSize] = useDebugState('pageSize', 10)
  const {data: ratesPage} = useAdminRates(page, pageSize)

  return <div className='admin_rates_table'>
    <Table_
      entitiesPage={ratesPage}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onRowClickNavigateCommonPath={'/admin/rates/'}
      onCreateNavigatePath={ADMIN__RATES_CREATE__PATH}
      config={
        {
          idKey: 'rate_id',
          columns: [
            {header: 'Rate type', entityPath: ['rate_type'], processor: (value: string) => toUiRateType(value as RateType)},
            {header: 'Rate, $', entityPath: ['value'], width: RATE_WIDTH},
            {header: 'Max distance, mi', entityPath: ['max_distance_mi'], width: 155},
            {header: 'Max length, in', entityPath: ['max_length_in']},
            {header: 'Max width, in', entityPath: ['max_width_in']},
            {header: 'Max height, in', entityPath: ['max_height_in']},
            {header: 'Max weight, lb', entityPath: ['max_weight_lb']},
          ],
        }
      }
    />
  </div>
}
