import './style.css'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export enum FileInputType {
  IMAGE = 'image/*',
  PDF = 'application/pdf',
}

interface FileInputProps {
  label: string
  type: FileInputType
  files?: FileList
  onChange: (event: any) => void
  singleFile?: boolean
  enablePreview?: boolean
  disabled?: boolean
}

export default function FileInput({ label, type, files, onChange, singleFile, enablePreview, disabled }: FileInputProps) {

  function preview(files?: FileList) {
    if (files && (enablePreview === undefined || enablePreview)) {
      return <div className='image_input__preview'>
        {Array.from(files).map((file, i) => {
          return <div key={file.name} className={i + '_row'}>
            <span className='Filename'>
                {file.name}
              {type == FileInputType.IMAGE && <img style={{ width: '55px' }} src={URL.createObjectURL(file)} alt={file.name} />}
            </span>
          </div>
        })}
      </div>
    } else {
      return <></>
    }
  }

  return <div className='image_input'>
    {preview(files)}
    <div className='image_input__upload_button'>
      <Button component='label' variant='contained' startIcon={<AddAPhotoIcon />} disabled={disabled}>
        {label}
        <VisuallyHiddenInput
          id='image_uploads' name='image_uploads' type='file' accept={type} multiple={!singleFile} onChange={e => onChange(e.target.files)}
          className='image_input__visually_hidden_input' />
      </Button>
    </div>
  </div>
}
