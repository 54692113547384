import './style.css'
import YouTube from 'react-youtube'
import { Typography } from '@mui/material'
import H from '../../components/elements/H/H'
import Divider_ from '../../components/elements/Divider/Divider'

const YOUTUBE_CONFIG = { height: '390', width: '100%', playerVars: { autoplay: 0 } }

export default function AboutUsPage() {
  const youtubeId = process.env.REACT_APP_ABOUT_US_YOUTUBE_ID

  function numberIcon(number: number) {
    return <div className={`about_us__how_it_works__number_${number}`}>
      {number}
    </div>
  }

  return <div className='about_us'>
    <H text='How iUjex works' variant='5' className='about_us__how_it_works__title' />
    {numberIcon(1)}
    <H text='Create Your Order' variant='6' className='about_us__how_it_works__text_1' />
    {numberIcon(2)}
    <H text='Pay online' variant='6' className='about_us__how_it_works__text_2' />
    {numberIcon(3)}
    <H text='Place Your Order ID on Your shipment' variant='6' className='about_us__how_it_works__text_3' />
    <H text='We will take care of the rest!' variant='6' className='about_us__how_it_works__text_4' />
    {/*https://developers.google.com/youtube/player_parameters*/}
    {youtubeId && <YouTube videoId={youtubeId} opts={YOUTUBE_CONFIG} onError={(e: any) => console.log(e)} className='about_us__how_it_works__video' />}
    <Divider_ text='About Us' className='about_us__divider__description' />
    <Typography className='about_us__description'>
      iUjex Shipping Service, founded in 2021, is a California-based delivery company offering an eco-friendly alternative to traditional shipping.
      We connect drivers with individuals needing parcels delivered along the drivers' routes.
      This peer-to-peer approach eliminates the need for warehouses and large fleets, allowing for faster and more affordable shipping.
      iUjex currently delivers packages between the San Francisco Bay Area and the Greater Los Angeles Area with plans to expand to new routes soon.
    </Typography>
  </div>
}
