import './style.css'
import Button_, { ButtonStyle } from '../../../elements/Button/Button'
import { UserDriverStatus } from '../../../../models/user/UserDriverStatus'
import { adminUserAcceptDriver, adminUserRejectDriver } from '../../../../api/user/admin_user_api'
import { BaseUser } from '../../../../models/user/BaseUser'
import DoneIcon from '@mui/icons-material/Done'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider_ from '../../../elements/Divider/Divider'

interface AdminUserDriverActionButtonsProps {
  user: BaseUser
  mutateUser: () => void
}

export default function AdminUserDriverActionButtons({ user, mutateUser }: AdminUserDriverActionButtonsProps) {

  function showActionButtons() {
    return user.driver_status !== null
      && user.driver_status !== UserDriverStatus.REJECTED
      && user.driver_status !== UserDriverStatus.NOT_INTERESTED
  }

  return <>
    {showActionButtons() && <>
        <Divider_ text='Admin Actions' className='admin_user_card__divider__admin_actions' />
        <div className='admin_user_card__user_driver_status_action_buttons'>
          {user.driver_status === UserDriverStatus.REQUESTED && <>
              <Typography className='admin_user_card__user_driver_status_action_buttons__text'>User wants to be a Driver:</Typography>
              <Button_
                  label='Accept'
                  disabled={user.driver_status !== UserDriverStatus.REQUESTED}
                  onClick={() => adminUserAcceptDriver(user.user_id).then(_ => mutateUser())}
                  logPath='AdminUserCard.adminUserAcceptDriver'
                  icon={<DoneIcon />}
              />
          </>}
            <Button_
                label='Reject'
                disabled={user.driver_status !== UserDriverStatus.REQUESTED && user.driver_status !== UserDriverStatus.ACCEPTED}
                buttonStyle={ButtonStyle.RED}
                onClick={() => adminUserRejectDriver(user.user_id).then(_ => mutateUser())}
                logPath='AdminUserCard.adminUserRejectDriver'
                icon={<CloseIcon />}
            />
        </div>
    </>
    }
  </>
}
