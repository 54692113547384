import { TextField } from '@mui/material'
import {CSSProperties} from "react";


export type TextVariant = 'standard' | 'outlined' | 'filled'
export type TextSize = 'small' | 'medium'

interface TextProps {
  label?: string,
  disabled?: boolean
  value?: any
  onChange?: (value: any) => void
  valid?: boolean
  textVariant?: TextVariant
  helperText?: string
  shrink?: boolean
  textSize?: TextSize
  className?: string
  style?: CSSProperties
}

export default function Text_({ label, disabled, value, onChange, valid, textVariant, helperText, shrink, textSize, className, style }: TextProps) {
  return <div className={className} style={style}>
    <TextField
      size={textSize}
      error={valid !== undefined ? !valid : false}
      sx={{
        // '& input:disabled': { textFillColor: 'black' },
        // '& MuiInputBase-input-MuiOutlinedInput-input': {
        '& .MuiInputBase-input': {
          height: "19px"
        }
    }}
      fullWidth
      disabled={onChange === undefined || disabled}
      label={label}
      variant={textVariant ? textVariant : (onChange ? 'outlined' : 'filled')}
      value={value ? value.toString() : undefined}
      InputLabelProps={{ shrink: shrink !== undefined ? shrink : true }}
      helperText={helperText}
      onChange={onChange ? (event) => {
          return onChange(event.target.value)
        }
        : undefined}
    />
  </div>
}
