import UserStripeSetupIntent from "../../../user/user/UserStripeSetupIntent/UserStripeSetupIntent/UserStripeSetupIntent";
import {OrderStatus} from "../../../../models/order/OrderStatus";
import React from "react";
import Title from "./Title";

interface Props {
  mutateOrder: () => void
}

export default function UserOrderCreateV2_7_PaymentSetup({mutateOrder}: Props) {
  return <div style={{
    display: "flex", flexDirection: "column",
    paddingTop: "0.25em", paddingBottom: "1.5em",
    // backgroundColor: "lightsalmon"
  }}>
    <Title text="Select payment method before final review" className="user_order_create_v2__payment_setup__title" />
    <div style={{width: "350px", margin: "auto"}}>
      <UserStripeSetupIntent orderStatus={OrderStatus.PAYMENT_SETUP_SUCCESS} mutateOrder={mutateOrder} />
    </div>
  </div>
}
