// DASHBOARDS
export const MAIN__PATH = '/'
export const DRIVER__DASHBOARD__PATH = '/driver'
export const ADMIN__DASHBOARD__PATH = '/admin'


// AGREEMENTS
export const USER__AGREEMENT_CARD__PATH = '/agreements/:agreementId'
export const USER__AGREEMENTS__PATH = '/agreements'
export const DRIVER__AGREEMENT_CARD__PATH = '/driver/agreements/:agreementId'
export const DRIVER__AGREEMENTS__PATH = '/driver/agreements'
export const ADMIN__AGREEMENT_CREATE__PATH = '/admin/agreements-create'
export const ADMIN__AGREEMENT_CARD__PATH = '/admin/agreements/:agreementId'
export const ADMIN__AGREEMENTS__PATH = '/admin/agreements'


// ORDERS
export const USER__ORDER_CREATE__PATH = '/orders-create'
export const USER__ORDER_CREATE_V2__PATH = '/orders-create-v2'
export const USER__ORDER_CREATE_CONTINUE_V2__PATH = '/orders-create-v2/:orderId'
export const USER__ORDER_CARD__PATH = '/orders/:orderId'
export const ORDER_TRACK_CARD__PATH = '/orders-track/:orderId'
export const ORDER_TRACKS__PATH = '/orders-track'
export const USER__ORDERS__PATH = '/orders'

export const DRIVER__ORDER_CARD__PATH = '/driver/orders/:orderId'
export const DRIVER__ORDERS__PATH = '/driver/orders'

export const ADMIN__ORDER_CARD__PATH = '/admin/orders/:orderId'
export const ADMIN__ORDERS__PATH = '/admin/orders'


// ROUTES
export const DRIVER__ROUTE_CREATE__PATH = '/driver/routes-create'
export const DRIVER__ROUTE_CARD__PATH = '/driver/routes/:routeId'
export const DRIVER__ROUTES__PATH = '/driver/routes'

export const ADMIN__ROUTE_CARD__PATH = '/admin/routes/:routeId'
export const ADMIN__ROUTES__PATH = '/admin/routes'


// RATES
export const ADMIN__RATES_CREATE__PATH = '/admin/rates-create'
export const ADMIN__RATE_CARD__PATH = '/admin/rates/:rateId'
export const ADMIN__RATES__PATH = '/admin/rates'


// USERS
export const PROFILE__PATH = '/profile'
export const BECOME_A_DRIVER = '/become-a-driver'
export const ADMIN__USER_CARD__PATH = '/admin/users/:userId'
export const ADMIN__USERS__PATH = '/admin/users'
export const DRIVER__STRIPE__PATH = '/driver/stripe'


// VISITS
export const ADMIN__VISITS__PATH = '/admin/visits'


// HELP
export const HELP__PATH = '/help'


// AUTH
export const ACCOUNT_ONBOARDING__PATH = '/account-onboarding'
export const LOGIN__PATH = '/login'
export const LOGIN_WITH_OTP__PATH = '/login/otp'
export const LOGIN_REDIRECT__PATH = '/login/redirect'
export const LOGOUT__PATH = '/logout'


// STATIC PAGES
export const ABOUT_US__PATH = '/about-us'
export const PAGE_404__PATH = '/404'
export const PAGE_403__PATH = '/403'
