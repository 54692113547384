import './style.css'
import { CircularProgress } from '@mui/material'
import OrderFilesSwipeableView from '../../OrderFilesSwipeableView/OrderFilesSwipeableView'
import { useAdminOrder } from '../../../../api/order/admin_order_api'
import OrderShortDetailsWithTimeline from '../../OrderShortDetailsWithTimeline/OrderShortDetailsWithTimeline'
import Map_ from '../../../elements/Map/Map'
import OrderCardPickupInfo from '../.././card_info/OrderCardPickupInfo/OrderCardPickupInfo'
import OrderCardDeliveryInfo from '../../card_info/OrderCardDeliveryInfo/OrderCardDeliveryInfo'
import OrderCardRouteInfo from '../../card_info/OrderCardRouteInfo/OrderCardRouteInfo'
import OrderCardPackageInfo from '../../card_info/OrderCardPackageInfo/OrderCardPackageInfo'
import OrderCardPaymentInfo from '../../card_info/OrderCardPaymentInfo/OrderCardPaymentInfo'
import OrderCardContactInfo from '../../card_info/OrderCardContactInfo/OrderCardContactInfo'
import OrderCardBaseInfo from '../../card_info/OrderCardBaseInfo/OrderCardBaseInfo'
import AdminOrderCardDriverInfo from '../../card_info/OrderCardDriverInfo/AdminOrderCardDriverInfo'
import Divider_ from '../../../elements/Divider/Divider'
import { buildMarkers, buildPolylines } from '../../../elements/Map/utils'
import { AdminOrderActions } from '../AdminOrderActions/AdminOrderActions'

interface AdminOrderCardProps {
  orderId: string
}

export default function AdminOrderCard({ orderId }: AdminOrderCardProps) {
  const { data: order, mutate: mutateOrder } = useAdminOrder(orderId)
  return <>
    {order ? <div className='admin_order_card__fields'>
        <Divider_ text='Order overview' className='admin_order_card__divider__overview' />
        <div className='admin_order_card__details'>
          <OrderShortDetailsWithTimeline orderId={order.order_id} orderStatus={order.status} userRate={order.user_rate} driverRate={order.driver_rate} driverExtraMiles={order.driver_extra_miles} />
          <Map_ polylines={buildPolylines(order)} markers={buildMarkers([order])} className='admin_order_card__map' />
          {order.files && <OrderFilesSwipeableView orderId={order.order_id} files={order.files} />}
        </div>
        <AdminOrderCardDriverInfo order={order} />
        <OrderCardPickupInfo order={order} />
        <OrderCardDeliveryInfo order={order} />
        <OrderCardRouteInfo order={order} />
        <OrderCardPackageInfo order={order} />
        <OrderCardPaymentInfo order={order} />
        <OrderCardContactInfo order={order} />
        <OrderCardBaseInfo order={order} />

        <AdminOrderActions mutateOrder={mutateOrder} orderId={order.order_id} orderStatus={order.status} />
      </div>
      : <CircularProgress />
    }
  </>
}
