import './style.css'
import Title from "./Title"
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material"


interface Props {
  disabled: boolean
  photosDecision?: boolean
  setPhotosDecision: (value: boolean) => void
}

export default function UserOrderCreateV2_4_PhotosDecision({disabled, photosDecision, setPhotosDecision}: Props) {

  function calculateState(): string {
    return photosDecision !== undefined
      ? photosDecision ? "1" : "0"
      : ""
  }

  return <div style={{display: "flex", flexDirection: "column", margin: "auto",
    paddingTop: "0.25em", paddingBottom: "1.5em",
    // backgroundColor: "lightgoldenrodyellow",
  }}>
    <Title text="Would you like to upload a photo of your package?" className="user_order_create_v2__pickup_and_delivery_details__photos_decision_title" />
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", rowGap: "1em"}}>
      <FormControl>
        <RadioGroup row value={calculateState()}>
          <FormControlLabel
            label="Yes"
            value="1"
            disabled={disabled}
            control={<Radio disabled={disabled} />}
            onClick={() => {
              if (!disabled) {
                setPhotosDecision(true)
              }
            }}
            className="user_order_create_v2__pickup_and_delivery_details__photos_decision_yes__checkbox"
          />
          <FormControlLabel
            label="No"
            value="0"
            disabled={disabled}
            control={<Radio disabled={disabled} />}
            onClick={() => {
              if (!disabled) {
                setPhotosDecision(false)
              }
            }}
            className="user_order_create_v2__pickup_and_delivery_details__photos_decision_no__checkbox"
          />
        </RadioGroup>
      </FormControl>
    </div>
  </div>
}
