import './style.css'
import DriverRoutesTable from '../../../components/route/driver/DriverRoutesTable/DriverRoutesTable'
import DriverTab, { DriverTabConfig } from '../../../components/tab/DriverTab/DriverTab'

export default function DriverRoutesPage() {
  return <div className='driver_routes_page'>
    <DriverTab tabIndex={DriverTabConfig.Routes.index} />
    <DriverRoutesTable />
  </div>
}
