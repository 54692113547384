import { RateType } from '../models/rate/AdminRate'

const CLIENT_TYPE = 'Client'
const DRIVER_TYPE = 'Driver'

export function toUiRateType(rateType: RateType): string {
  switch (rateType) {
    case RateType.CLIENT:
      return CLIENT_TYPE
    case RateType.DRIVER:
      return DRIVER_TYPE
  }
}

export function fromUiRateType(uiRateType?: string): RateType | undefined {
  switch (uiRateType) {
    case CLIENT_TYPE:
      return RateType.CLIENT
    case DRIVER_TYPE:
      return RateType.DRIVER
  }
}
