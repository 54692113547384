import useSWR, { SWRResponse } from 'swr'
import { AdminRoute } from '../../models/route/AdminRoute'
import { PageResponse } from '../../models/Page'
import { usePageable } from '../../utils/swr_utils'

export const useAdminRoute = (routeId: string): SWRResponse<AdminRoute> => {
  return useSWR<AdminRoute>([`/admin/routes/${routeId}`])
}

export const useAdminRoutes = (page?: number, pageSize?: number): SWRResponse<PageResponse<AdminRoute>> => {
  return usePageable('/admin/routes/search', { page: page, page_size: pageSize })
}
