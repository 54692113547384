import './style.css'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useDebugState } from 'use-named-state'
import { setupIntentForOrders } from '../../../../../api/user/user_api'
import { OrderStatus } from '../../../../../models/order/OrderStatus'
import Submit_ from '../../../../elements/Submit/Submit'

interface UserStripeSetupIntentFormProps {
  orderStatus: OrderStatus
  mutateOrder: () => void
}


export default function UserStripeSetupIntentForm({ orderStatus, mutateOrder }: UserStripeSetupIntentFormProps) {
  const stripe = useStripe()
  const elements = useElements() || undefined

  const [errorMessage, setErrorMessage] = useDebugState<string | undefined>('errorMessage', undefined)
  const [loading, setLoading] = useDebugState<boolean>('loading', false)

  const handleError = (error: any) => {
    setLoading(false)
    setErrorMessage(error.message)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    if (!stripe) {
      return
    }

    setLoading(true)

    // @ts-ignore
    const { error: submitError } = await elements.submit()
    if (submitError) {
      handleError(submitError)
      return
    }

    setupIntentForOrders()
      .then(response => stripe.confirmSetup({
          elements: elements,
          clientSecret: response.client_secret,
          confirmParams: { return_url: window.location.href },
        })
      )
      .then(() => {
        debugger
        setLoading(false)
        return new Promise(() => {
          let attempts = 0
          const checkStatus = setInterval(() => {
            attempts++
            mutateOrder()
            if (orderStatus === OrderStatus.PAYMENT_SETUP_SUCCESS || orderStatus === OrderStatus.PAYMENT_SETUP_FAILED || attempts >= 10) {
              clearInterval(checkStatus)
            }
          }, 500)
        })
      })
  }

  return <>
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Submit_ onClick={handleSubmit} isUploading={!stripe || loading} />
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  </>
}
