import useSWR, { SWRResponse } from 'swr'
import { AdminOrder, AdminOrderDeliverRequest, AdminOrderPickupRequest, AdminOrderTable } from '../../models/order/AdminOrder'
import { http } from '../http'
import { PageResponse } from '../../models/Page'
import { useBaseOrders } from '../../utils/swr_utils'
import { OrderStatus } from '../../models/order/OrderStatus'
import { DriverOrder, DriverOrderPickupRequest } from '../../models/order/DriverOrder'

export const useAdminOrder = (orderId: string): SWRResponse<AdminOrder> => {
  return useSWR<AdminOrder>([`/admin/orders/${orderId}`])
}

export const useAdminOrders = (page?: number, pageSize?: number, orderStatus?: OrderStatus): SWRResponse<PageResponse<AdminOrderTable>> => {
  return useBaseOrders('/admin/orders/search', page, pageSize, orderStatus)
}

export const adminOrderMarkAsPaymentHeld = (orderId: string): Promise<AdminOrder> => {
  return http.putData<void, AdminOrder>(`/admin/orders/${orderId}/mark-payment-hold`)
}

export const adminOrderPickup = (orderId: string, request: AdminOrderPickupRequest, files: FileList): Promise<DriverOrder> => {
  return http.putMultipartData(`/admin/orders/${orderId}/pickup`, request, files)
}

export const adminOrderDeliver = (orderId: string, request: AdminOrderDeliverRequest, files: FileList): Promise<DriverOrder> => {
  return http.putMultipartData(`/admin/orders/${orderId}/deliver`, request, files)
}
