import {Carousel} from "react-responsive-carousel";
import {PhotoAndLabel} from "./PhotoAndLabel";

interface Props {
  photosAndLabels: PhotoAndLabel[]
  showLabel?: boolean
}

export default function SwipeableView(props: Props) {
  return <>
    {props.photosAndLabels.length > 0 && <div className='order_files_swipeable_view__carousel' >
        <Carousel useKeyboardArrows={true}>
          {props.photosAndLabels.map((photosAndLabel, index) => (
            <>
              {
                (props.showLabel === undefined || props.showLabel)
                  ? <p>{photosAndLabel.label}</p>
                  : <></>
              }
              <img style={{borderRadius: "10px"}} alt={index.toString()} src={`data:image/*;charset=utf-8;base64,${photosAndLabel.binary}`} />
            </>
          ))}
        </Carousel>
    </div>}
  </>
}
