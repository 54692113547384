import './style.css'
import Typography from '@material-ui/core/Typography'
import {OrderStatus} from '../../../models/order/OrderStatus'
import Card from '@mui/material/Card'
import {CardContent} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import IconButton from '@mui/material/IconButton'
import OrderTimeline from '../OrderTimeline/OrderTimeline'
import {Link} from 'react-router-dom'
import {ORDER_TRACKS__PATH} from '../../../config/page_paths'

interface OrderShortDetailsWithTimelineProps {
  orderId: string
  orderStatus: OrderStatus
  userRate?: number
  driverRate?: number
  driverExtraMiles?: number
  mutateOrder?: () => void
}

export default function OrderShortDetailsWithTimeline({orderId, orderStatus, userRate, driverRate, driverExtraMiles, mutateOrder}: OrderShortDetailsWithTimelineProps) {
  return <div className='order_short_details_with_timeline'>
    <Card className='order_short_details_with_timeline__card'>
      <CardContent>
        <Typography>
          <div className='order_short_details_with_timeline__card__id'>
            <b>ID:</b> {orderId}
            <IconButton size='small' color='primary' component={Link} to={`${ORDER_TRACKS__PATH}/${orderId}`} target='_blank'>
              <OpenInNewIcon fontSize='inherit' />
            </IconButton>
          </div>
        </Typography>
        {userRate && <Typography><b>Rate:</b> {userRate} $</Typography>}
        {driverRate && <Typography><b>Rate:</b> {driverRate} $</Typography>}
        {driverExtraMiles && <Typography><b>Extra miles:</b> {driverExtraMiles}</Typography>}
      </CardContent>
    </Card>
    <OrderTimeline orderStatus={orderStatus} />
  </div>
}
