import './style.css'
import { BaseUser } from '../../../../models/user/BaseUser'
import Divider_ from '../../../elements/Divider/Divider'
import Text_ from '../../../elements/Text/Text'

interface UserCardPaymentInfoProps {
  user: BaseUser
}

export default function UserCardPaymentInfo({ user }: UserCardPaymentInfoProps) {
  return <>
    <Divider_ text='Payment info' className='user_card__divider__basic_payment_info' />
    <Text_ label='Stripe Customer ID' value={user.stripe_data?.customer_id} className='user_card__stripe_data_customer_id' />
    <Text_ label='Stripe Session ID' value={user.stripe_data?.setup_intent_id} className='user_card__stripe_data_session_id' />
    <Text_ label='Stripe Payment Method ID' value={user.stripe_data?.payment_method_id} className='user_card__stripe_data_payment_method_id' />
  </>
}
