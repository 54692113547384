import {Typography} from "@material-ui/core";
import {useMobile} from "./utils";

interface Props {
  text: string
  className: string
}

export default function Title(props: Props) {

  return <>
    <Typography
      variant="h5"
      style={{
        alignSelf: "center", fontWeight: "600",
        fontSize: useMobile() ? "20px" : "31px",
        padding: "1em", paddingBottom: "0.5em",
        textAlign: "center"
      }}
      className={props.className}
    >
      {props.text}
    </Typography>
  </>
}
