import {useDebugState} from "use-named-state"
import {UserOrderCreateV2_AddressAndPackagesRequest, UserOrderUpdateV2_ConfirmAndAgreementRequest, UserOrderUpdateV2_DetailsAndPhotosRequest, UserOrderV2} from "../../../../models/order/UserOrderV2"
import React, {ReactNode, useEffect, useRef} from "react"
import {orderCreateV2_draft, orderGetV2, orderGetV2_confirmAndHold, orderGetV2_setUser, orderUpdateV2_pickupAndDeliveryDetailsAndPhotos} from "../../../../api/order/order_v2_api"
import {useUser} from "../../../../pages/Layout/Layout";
import Cookies from "js-cookie";
import {PageState} from "./PageState";
import {AddressRequest} from "../../../../models/Address";
import UserOrderCreateV2_1_Address from "./UserOrderCreateV2_1_Address";
import UserOrderCreateV2_2_Package from "./UserOrderCreateV2_2_Package";
import UserOrderCreateV2_3_PickupAndDeliveryDetails from "./UserOrderCreateV2_3_PickupAndDeliveryDetails";
import UserOrderCreateV2_6_Login from "./UserOrderCreateV2_6_Login";
import UserOrderCreateV2_7_PaymentSetup from "./UserOrderCreateV2_7_PaymentSetup";
import UserOrderCreateV2_8_ConfirmAndAgreement from "./UserOrderCreateV2_8_ConfirmAndAgreement";
import UserOrderCreateV2_9_Done from "./UserOrderCreateV2_9_Done";
import {useUserAgreementsActive} from "../../../../api/agreement/user_agreement_api";
import {OrderPackage} from "../../../../models/order/OrderPackage";
import UserOrderCreateV2_4_PhotosDecision from "./UserOrderCreateV2_4_PhotosDecision";
import {scrollToElement} from "./ScrollUtils";
import {OrderStatus} from "../../../../models/order/OrderStatus";
import UserOrderCreateV2_5_PhotosUploadAndProceed from "./UserOrderCreateV2_5_PhotosUploadAndProceed";
import {OrderFiles} from "../../../../models/order/OrderFiles";
import UserOrderCreateV2_8FooterInto from "./UserOrderCreateV2_FooterInto";


export interface OrderPackage2 {
  length_in?: number
  length_in_valid?: boolean
  width_in?: number
  width_in_valid?: boolean
  height_in?: number
  height_in_valid?: boolean
  weight_lb?: number
  weight_lb_valid?: boolean
}

interface UserOrderCreateV2Props {
  orderId?: string
}

export default function UserOrderCreateV2({orderId}: UserOrderCreateV2Props) {

  const {user, mutateUser} = useUser()
  const [order, setOrder] = useDebugState<UserOrderV2 | undefined>('order', undefined)
  const {data: agreementsPage} = useUserAgreementsActive()

  const [pageState, setPageState] = useDebugState<PageState>('pageState', PageState.Address)

  useEffect(() => {
    if (user === undefined) {
      const token = Cookies.get('jwt_token')
      if (token) {
        localStorage.setItem('token', token)
        mutateUser && mutateUser()
      }
    }
  }, [user])

  useEffect(() => {
    if (orderId) {
      orderGetV2(orderId).then(setOrder)
    }
  }, [orderId])

  useEffect(() => {
    if (!order) {
      setPageState(PageState.Address)
    } else {
      switch (order.status) {
        case OrderStatus.NEW__ADDRESS_AND_PACKAGE:
          setPageState(PageState.PickupAndDeliveryDetails)
          break
        case OrderStatus.NEW__DETAILS_AND_PHOTOS:
          if (!user) {
            setPageState(PageState.Login)
            break
          } else if (!user.stripe_data) {
            setPageState(PageState.PaymentSetup)
            break
          } else {
            setPageState(PageState.ConfirmAndAgreement)
            break
          }
        case OrderStatus.PAYMENT_SETUP_SUCCESS:
          setPageState(PageState.ConfirmAndAgreement)
          break
        case OrderStatus.PAYMENT_HOLD_SUCCESS:
          setPageState(PageState.Done)
      }


      setPhotosDecision(calculatePhotosDecision(order.files))
      setPickupFrom(order.pickup_from)
      setDeliveryTo(order.delivery_to)
      setPackages(order.packages)

      if (!order.user_id && user) {
        orderGetV2_setUser(order.order_id)
          .then(o => setOrder(o))
      }
    }

  }, [order, user])

  useEffect(() => {
    switch (pageState) {
      case PageState.Address:
        scrollToElement([])
        break
      case PageState.Package:
        scrollToElement([
          addressRef,
        ])
        break
      case PageState.PickupAndDeliveryDetails:
        scrollToElement([
          addressRef,
          packageRef,
        ])
        break
      case PageState.PhotosDecision:
        scrollToElement([
          addressRef,
          packageRef,
          pickupAndDeliveryDetailsRef,
        ])
        break
      case PageState.PhotosUploadAndProceed:
        scrollToElement([
          addressRef,
          packageRef,
          pickupAndDeliveryDetailsRef,
          photosDecisionRef,
        ])
        break
      case PageState.Login:
        scrollToElement([
          addressRef,
          packageRef,
          pickupAndDeliveryDetailsRef,
          photosDecisionRef,
          photosUploadAndProceedRef,
        ])
        break
      case PageState.PaymentSetup:
        scrollToElement([
          addressRef,
          packageRef,
          pickupAndDeliveryDetailsRef,
          photosDecisionRef,
          photosUploadAndProceedRef,
          loginRef,
        ])
        break
      case PageState.ConfirmAndAgreement:
        scrollToElement([
          addressRef,
          packageRef,
          pickupAndDeliveryDetailsRef,
          photosDecisionRef,
          photosUploadAndProceedRef,
          loginRef,
          paymentSetupRef,
        ])
        break
      case PageState.Done:
        scrollToElement([
          addressRef,
          packageRef,
          pickupAndDeliveryDetailsRef,
          photosDecisionRef,
          photosUploadAndProceedRef,
          loginRef,
          paymentSetupRef,
          confirmAndAgreementRef,
        ])
        break
    }
  }, [pageState])

  function cleanUp() {
    setOrder(undefined)
    setPageState(PageState.Address)
    setPickupFrom(undefined)
    setDeliveryTo(undefined)
    setPackages([{} as OrderPackage2])
    setPickupAtAsap(true)
    setPickupAt(undefined)
    setPickupContactless(true)
    setPickupPhoneNumber(undefined)
    setDeliveryAtAsap(true)
    setDeliveryAt(undefined)
    setDeliveryContactless(true)
    setDeliveryPhoneNumber(undefined)
    setFiles(undefined)
  }


  // Address
  const [pickupFrom, setPickupFrom] = useDebugState<AddressRequest | undefined>('pickupFrom', undefined)
  const [deliveryTo, setDeliveryTo] = useDebugState<AddressRequest | undefined>('deliveryTo', undefined)

  // Package
  const [packages, setPackages] = useDebugState<OrderPackage2[]>('packages', [{} as OrderPackage2])

  // Details and Photos
  const [pickupAtAsap, setPickupAtAsap] = useDebugState<boolean>('pickupAtAsap', true)
  const [pickupAt, setPickupAt] = useDebugState<any>('pickupAt', undefined)
  const [pickupContactless, setPickupContactless] = useDebugState<boolean>('pickupContactless', true)
  const [pickupPhoneNumber, setPickupPhoneNumber] = useDebugState<string | undefined>('pickupPhoneNumber', undefined)
  const [deliveryAt, setDeliveryAt] = useDebugState<any>('deliveryAt', undefined)
  const [deliveryAtAsap, setDeliveryAtAsap] = useDebugState<boolean>('deliveryAtAsap', true)
  const [deliveryContactless, setDeliveryContactless] = useDebugState<boolean>('deliveryContactless', true)
  const [deliveryPhoneNumber, setDeliveryPhoneNumber] = useDebugState<string | undefined>('deliveryPhoneNumber', undefined)
  const [files, setFiles] = useDebugState<FileList | undefined>('files', undefined)

  // START: Address
  const addressRef = useRef<HTMLDivElement>(null)

  function showAddress(): ReactNode {
    return <div className="user_order_create_v2__address" ref={addressRef}>
      <UserOrderCreateV2_1_Address
        pageState={pageState}
        disabled={pageState > PageState.Address}
        pickUpFrom={pickupFrom} setPickupFrom={onPickupFromChange}
        deliveryTo={deliveryTo} setDeliveryTo={onDeliveryToChange}
      />
    </div>
  }

  function onPickupFromChange(value: AddressRequest) {
    setPickupFrom(value)
    if (deliveryTo) {
      setPageState(PageState.Package)
    }
  }

  function onDeliveryToChange(value: AddressRequest) {
    setDeliveryTo(value)
    if (pickupFrom) {
      setPageState(PageState.Package)
    }
  }

  // END: Address

  // START: Package
  const packageRef = useRef<HTMLDivElement>(null)

  function visibleIf(condition: boolean): string {
    return condition ? 'block visible' : 'block'
  }

  function showPackage(): ReactNode {
    const visibleCondition = pageState >= PageState.Package
    const disableCondition = pageState > PageState.Package
    return <div className={`user_order_create_v2__packages ${visibleIf(visibleCondition)}`} ref={packageRef}>
      {visibleCondition &&
          <UserOrderCreateV2_2_Package
              disabled={disableCondition}
              packages={packages} setPackages={setPackages}
              onPackageComplete={() => orderCreateV2_draft(
                {
                  pickup_from: pickupFrom,
                  delivery_to: deliveryTo,
                  packages: packages
                    .filter(p => p.length_in !== undefined && p.width_in !== undefined && p.height_in !== undefined)
                    .map(p => {
                      return {
                        length_in: p.length_in,
                        width_in: p.width_in,
                        height_in: p.height_in,
                        weight_lb: p.weight_lb,
                      } as OrderPackage
                    })
                } as UserOrderCreateV2_AddressAndPackagesRequest
              )}
              thenAction={(order: UserOrderV2) => {
                setOrder(order)
                window.history.pushState({}, '', '/orders-create-v2/' + order?.order_id)
              }}
          />}
    </div>
  }

  // END: Package

  // START: Pickup and delivery details
  const pickupAndDeliveryDetailsRef = useRef<HTMLDivElement>(null)

  function showPickupAndDeliveryDetails() {
    const visibleCondition = pageState >= PageState.PickupAndDeliveryDetails
    const disableCondition = pageState > PageState.PhotosDecision
    return <div className={`user_order_create_v2__pickup_and_delivery_details ${visibleIf(visibleCondition)}`} ref={pickupAndDeliveryDetailsRef}>
      {visibleCondition &&
          <UserOrderCreateV2_3_PickupAndDeliveryDetails
              disabled={disableCondition}
              userRate={order?.user_rate || 0}
              pickupAtAsap={pickupAtAsap} setPickupAtAsap={setPickupAtAsap}
              pickupAt={pickupAt} setPickupAt={setPickupAt}
              pickupContactless={pickupContactless} setPickupContactless={setPickupContactless}
              pickupPhoneNumber={pickupPhoneNumber} setPickupPhoneNumber={setPickupPhoneNumber}
              deliveryAt={deliveryAt} setDeliveryAt={setDeliveryAt}
              deliveryAtAsap={deliveryAtAsap} setDeliveryAtAsap={setDeliveryAtAsap}
              deliveryContactless={deliveryContactless} setDeliveryContactless={setDeliveryContactless}
              deliveryPhoneNumber={deliveryPhoneNumber} setDeliveryPhoneNumber={setDeliveryPhoneNumber}
          />}
    </div>
  }

  // END: Pickup and delivery details

  // START: Photos decision
  const photosDecisionRef = useRef<HTMLDivElement>(null)

  function calculatePhotosDecision(files?: OrderFiles): boolean | undefined {
    if (!files) {
      return undefined
    } else {
      return (files.photos_on_creation?.length || 0) > 0
    }
  }

  const [photosDecision, setPhotosDecision] = useDebugState<boolean | undefined>('photosDecision', calculatePhotosDecision(order?.files))

  function showPhotosDecision() {
    const visibleCondition = pageState >= PageState.PickupAndDeliveryDetails
    const disableCondition = pageState > PageState.PhotosUploadAndProceed
    return <div className={`user_order_create_v2__photo_decision ${visibleIf(visibleCondition)}`} ref={photosDecisionRef}>
      {visibleCondition && <>
          <UserOrderCreateV2_4_PhotosDecision
              disabled={disableCondition}
              photosDecision={photosDecision} setPhotosDecision={onPhotosDecisionChange}
          />
      </>}
    </div>
  }

  function onPhotosDecisionChange(value: boolean) {
    setPhotosDecision(value)
    setPageState(PageState.PhotosDecision)
    if (!value) {
      setFiles(undefined)
    }
  }

  // END: Photos decision

  // START: Photos upload and proceed
  const photosUploadAndProceedRef = useRef<HTMLDivElement>(null)

  function showPhotosUploadAndProceed() {
    const visibleCondition = photosDecision !== undefined && pageState >= PageState.PhotosDecision
    return <div className={`user_order_create_v2__photos_upload_and_proceed ${visibleIf(visibleCondition)}`} ref={photosUploadAndProceedRef}>
      {visibleCondition &&
          <UserOrderCreateV2_5_PhotosUploadAndProceed
              disabled={pageState > PageState.PhotosUploadAndProceed}
              photosDecision={photosDecision}
              files={files} setFiles={onFilesChange}
              onProceedClick={onProceedClick}
          />}
    </div>
  }

  function onFilesChange(value: FileList) {
    setFiles(value)
    setPageState(PageState.PhotosUploadAndProceed)
  }


  function onProceedClick() {
    if (order) {
      orderUpdateV2_pickupAndDeliveryDetailsAndPhotos(
        order.order_id,
        {
          pickup_at: pickupAt,
          pickup_contactless: pickupContactless,
          pickup_phone_number: pickupPhoneNumber,
          delivery_at: deliveryAt,
          delivery_contactless: deliveryContactless,
          delivery_phone_number: deliveryPhoneNumber,
        } as UserOrderUpdateV2_DetailsAndPhotosRequest,
        files
      ).then(setOrder)
    }
  }

  // END: Photos upload and proceed


  // START: Login
  const loginRef = useRef<HTMLDivElement>(null)

  function showLogin(): ReactNode {
    const visibleCondition = pageState === PageState.Login && !user
    return <div className={`user_order_create_v2__login ${visibleIf(visibleCondition)}`} ref={loginRef}>
      {visibleCondition && <UserOrderCreateV2_6_Login />}
    </div>
  }

  // END: Login

  // START: Payment Setup
  const paymentSetupRef = useRef<HTMLDivElement>(null)

  function showPaymentSetup(): ReactNode {
    const visibleCondition = user !== undefined && user.stripe_data !== undefined && pageState === PageState.PaymentSetup
    return <div className={`user_order_create_v2__payment_setup ${visibleIf(visibleCondition)}`} ref={paymentSetupRef}>
      {visibleCondition &&
          <UserOrderCreateV2_7_PaymentSetup mutateOrder={onPaymentSetupComplete} />}
    </div>
  }

  function onPaymentSetupComplete() {
    if (order) {
      orderGetV2(order.order_id)
        .then(setOrder)
    }
  }

  // END: Payment Setup

  // START: Confirm and Agreement
  const confirmAndAgreementRef = useRef<HTMLDivElement>(null)

  function showConfirmAndAgreement(): ReactNode {
    const visibleCondition = pageState >= PageState.ConfirmAndAgreement && order !== undefined
    return <div className={`user_order_create_v2__confirm_and_agreement ${visibleIf(visibleCondition)}`} ref={confirmAndAgreementRef}>
      {visibleCondition &&
          <UserOrderCreateV2_8_ConfirmAndAgreement
              pageState={pageState}
              handleConfirmClick={onShowConfirmAndAgreementComplete}
              agreementsPage={agreementsPage}
              order={order}
          />}
    </div>
  }

  function onShowConfirmAndAgreementComplete() {
    if (order) {
      orderGetV2_confirmAndHold(
        order.order_id,
        window.location.href,
        {
          agreement_ids: agreementsPage?.content.map(e => e.agreement_id)
        } as UserOrderUpdateV2_ConfirmAndAgreementRequest
      ).then(setOrder)
    }
  }

  // END: Confirm and Agreement

  // START: Done
  const doneRef = useRef<HTMLDivElement>(null)

  function showDone(): ReactNode {
    const visibleCondition = pageState == PageState.Done
    return <div className={`user_order_create_v2__done ${visibleIf(visibleCondition)}`} ref={doneRef}>
      {visibleCondition &&
          <UserOrderCreateV2_9_Done />}
    </div>
  }

  // END: Done

  function showFooter(): ReactNode {
    return <div className="user_order_create_v2__footer">
      <UserOrderCreateV2_8FooterInto />
    </div>
  }

  return <div className="user_order_create_v2" style={{maxWidth: "900px", margin: "auto"}}>
    {showAddress()}
    {showPackage()}
    {showPickupAndDeliveryDetails()}
    {showPhotosDecision()}
    {showPhotosUploadAndProceed()}
    {showLogin()}
    {showPaymentSetup()}
    {showConfirmAndAgreement()}
    {showDone()}
    {showFooter()}
    <div className="user_order_create_v2__extra_for_scroll" style={{height: '2000px'}} />
  </div>
}
