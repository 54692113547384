import { MarkerType } from './MarkerType'

export interface Marker {
  markerId: string
  entityId: string
  lat: number
  lng: number
  label: string
  color: string
  url: string
  type: MarkerType
  icon: google.maps.Symbol
  payload: any
}

export const PICKUP_FROM_ICON = {
  path: 'M 45 0 C 25.463 0 9.625 15.838 9.625 35.375 c 0 8.722 3.171 16.693 8.404 22.861 L 45 90 l 26.97 -31.765 c 5.233 -6.167 8.404 -14.139 8.404 -22.861 C 80.375 15.838 64.537 0 45 0 z M 45 48.705 c -8.035 0 -14.548 -6.513 -14.548 -14.548 c 0 -8.035 6.513 -14.548 14.548 -14.548 s 14.548 6.513 14.548 14.548 C 59.548 42.192 53.035 48.705 45 48.705 z',
  fillColor: 'green',
  fillOpacity: 1,
  strokeWeight: 1,
  rotation: 0,
  scale: .4,
  anchor: null,
} as google.maps.Symbol

export const DELIVERY_TO_ICON = {
  path: 'M 45 0 C 25.463 0 9.625 15.838 9.625 35.375 c 0 8.722 3.171 16.693 8.404 22.861 L 45 90 l 26.97 -31.765 c 5.233 -6.167 8.404 -14.139 8.404 -22.861 C 80.375 15.838 64.537 0 45 0 z M 45 48.705 c -8.035 0 -14.548 -6.513 -14.548 -14.548 c 0 -8.035 6.513 -14.548 14.548 -14.548 s 14.548 6.513 14.548 14.548 C 59.548 42.192 53.035 48.705 45 48.705 z',
  fillColor: 'red',
  fillOpacity: 1,
  strokeWeight: 1,
  rotation: 0,
  scale: .4,
  anchor: null,
} as google.maps.Symbol

export const DRIVER_ICO = {
  path: 'M37.409,18.905l-4.946-7.924c-0.548-0.878-1.51-1.411-2.545-1.411H3c-1.657,0-3,1.343-3,3v16.86c0,1.657,1.343,3,3,3h0.787 c0.758,1.618,2.391,2.75,4.293,2.75s3.534-1.132,4.292-2.75h20.007c0.758,1.618,2.391,2.75,4.293,2.75 c1.9,0,3.534-1.132,4.291-2.75h0.787c1.656,0,3-1.343,3-3v-2.496C44.75,22.737,41.516,19.272,37.409,18.905z M8.087,32.395 c-1.084,0-1.963-0.879-1.963-1.963s0.879-1.964,1.963-1.964s1.963,0.88,1.963,1.964S9.171,32.395,8.087,32.395z M26.042,21.001 V15.57v-2.999h3.876l5.264,8.43H26.042z M36.671,32.395c-1.084,0-1.963-0.879-1.963-1.963s0.879-1.964,1.963-1.964 s1.963,0.88,1.963,1.964S37.755,32.395,36.671,32.395z',
  fillColor: 'blue',
  fillOpacity: 1,
  strokeWeight: 1,
  rotation: 0,
  scale: 0.8,
  anchor: null,
} as google.maps.Symbol

export const PICKUP_GIFT_ICO = {
  path: 'M13.5 7.5H1.5M13.5 7.5C14.0523 7.5 14.5 7.05228 14.5 6.5V5.5C14.5 4.94772 14.0523 4.5 13.5 4.5H1.5C0.947715 4.5 0.5 4.94772 0.5 5.5V6.5C0.5 7.05228 0.947716 7.5 1.5 7.5M13.5 7.5V12.5C13.5 13.6046 12.6046 14.5 11.5 14.5H3.5C2.39543 14.5 1.5 13.6046 1.5 12.5L1.5 7.5M7.5 4.5V3.5M7.5 4.5H4.21429C3.26751 4.5 2.5 3.73249 2.5 2.78571V2.5C2.5 1.39543 3.39543 0.5 4.5 0.5C6.15685 0.5 7.5 1.84315 7.5 3.5M7.5 4.5H10.7857C11.7325 4.5 12.5 3.73249 12.5 2.78571V2.5C12.5 1.39543 11.6046 0.5 10.5 0.5C8.84315 0.5 7.5 1.84315 7.5 3.5M7.5 4.5V14.5',
  fillColor: 'green',
  fillOpacity: 0.8,
  strokeWeight: 1,
  rotation: 0,
  scale: 2.1,
  anchor: null,
} as google.maps.Symbol

export const DELIVERY_GIFT_ICO = {
  path: 'M13.5 7.5H1.5M13.5 7.5C14.0523 7.5 14.5 7.05228 14.5 6.5V5.5C14.5 4.94772 14.0523 4.5 13.5 4.5H1.5C0.947715 4.5 0.5 4.94772 0.5 5.5V6.5C0.5 7.05228 0.947716 7.5 1.5 7.5M13.5 7.5V12.5C13.5 13.6046 12.6046 14.5 11.5 14.5H3.5C2.39543 14.5 1.5 13.6046 1.5 12.5L1.5 7.5M7.5 4.5V3.5M7.5 4.5H4.21429C3.26751 4.5 2.5 3.73249 2.5 2.78571V2.5C2.5 1.39543 3.39543 0.5 4.5 0.5C6.15685 0.5 7.5 1.84315 7.5 3.5M7.5 4.5H10.7857C11.7325 4.5 12.5 3.73249 12.5 2.78571V2.5C12.5 1.39543 11.6046 0.5 10.5 0.5C8.84315 0.5 7.5 1.84315 7.5 3.5M7.5 4.5V14.5',
  fillColor: 'red',
  fillOpacity: 0.8,
  strokeWeight: 1,
  rotation: 0,
  scale: 2.1,
  anchor: null,
} as google.maps.Symbol


try {
  PICKUP_FROM_ICON.anchor = new google.maps.Point(46, 89)
  DELIVERY_TO_ICON.anchor = new google.maps.Point(46, 89)
  DRIVER_ICO.anchor = new google.maps.Point(46, 89)
  PICKUP_GIFT_ICO.anchor = new google.maps.Point(7.5, 15)
  DELIVERY_GIFT_ICO.anchor = new google.maps.Point(7.5, 15)
} catch (error) {
  console.error('Error initializing anchor:', error)
}
