import './style.css'
import { useParams } from 'react-router-dom'
import UserOrderCard from '../../../components/order/user/UserOrderCard/UserOrderCard'
import UserTab, { UserTabConfig } from '../../../components/tab/UserTab/UserTab'

export default function UserOrderCardPage() {
  const { orderId } = useParams()
  return <div className='user_order_card_page'>
    <UserTab tabIndex={UserTabConfig.Orders.index} />
    <UserOrderCard orderId={orderId as string} />
  </div>
}
