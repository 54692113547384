import { http } from '../http'
import { User, UserBecomeDriverRequest, UserStripeSetupIntentResponse, UserUpdateRequest } from '../../models/user/User'
import { ACCOUNT_ONBOARDING__PATH } from '../../config/page_paths'

export const getMe = () => {
  return http.getData<User>('/users/me')
}

export const meUpdate = (request: UserUpdateRequest): Promise<User> => {
  return http.putData<UserUpdateRequest, User>('/users/me', request)
}

export const setupIntentForOrders = (): Promise<UserStripeSetupIntentResponse> => {
  return http.putData<void, UserStripeSetupIntentResponse>('/users/me/setup-intent-for-orders')
}

export const meBecomeDriver = (value: boolean): Promise<User> => {
  return http.putData<UserUpdateRequest, User>(`/users/me/become-a-driver?value=${value}`)
}

export const meBecomeDriverCreateAccount = (returnUrl: string, agreement_ids: string[]): Promise<User> => {
  const request = {
    return_url: returnUrl,
    refresh_url: ACCOUNT_ONBOARDING__PATH,
    agreement_ids: agreement_ids,
  } as UserBecomeDriverRequest
  return http.putData<UserBecomeDriverRequest, User>(`/users/me/become-a-driver/create-account`, request)
}

export const meBecomeDriverCreateAccountLink = (returnUrl: string): Promise<User> => {
  return http.putData<void, User>(`/users/me/become-a-driver/create-account-link?return_url=${returnUrl}&refresh_url=${ACCOUNT_ONBOARDING__PATH}`)
}
