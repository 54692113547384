export enum RateType {
  CLIENT = 'CLIENT',
  DRIVER = 'DRIVER',
}

export interface AdminRateCreateRequest {
  rate_type: RateType
  max_distance_mi: number
  max_length_in: number
  max_width_in: number
  max_height_in: number
  max_weight_lb?: number
  value: number
}

export interface AdminRateUpdateRequest {
  rate_type: RateType
  max_distance_mi?: number
  max_weight_lb?: number
  max_length_in?: number
  max_width_in?: number
  max_height_in?: number
  value?: number
}

export interface AdminRate {
  rate_id: string
  created_at: string
  updated_at?: string
  rate_type: RateType
  max_distance_mi: number
  max_length_in: number
  max_width_in: number
  max_height_in: number
  max_weight_lb?: number
  value: number
}

export interface AdminRateCalculateRequest {
  rate_type: RateType
  distance_mi: number
  length_in: number
  width_in: number
  height_in: number
  weight_lb?: number
}