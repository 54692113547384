import { FormControl, InputLabel, Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { convertToSnakeCase } from '../../../utils/string_utils'

interface SelectProps {
  label: string
  value?: string
  onChange: (value: any) => void
  values: string[]
  displayEmpty?: boolean
  className?: string
}

export default function SelectField_({ label, value, onChange, values, displayEmpty, className }: SelectProps) {
  const id = convertToSnakeCase(label)
  return <div className={className}>
    <FormControl fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        variant='standard'
        labelId={`${id}_label`}
        id={id} value={value || ''}
        label={label}
        displayEmpty={displayEmpty !== undefined ? displayEmpty : false}
        onChange={(event) => onChange(event.target.value)}
      >
        {values.map(v => <MenuItem value={v}>{v}</MenuItem>)}
      </Select>
    </FormControl>
  </div>
}
