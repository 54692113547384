import './style.css'
import React from 'react'
import A from '../A/A'

const COMPANY_PHONE = process.env.REACT_APP_COMPANY_PHONE
const COMPANY_EMAIL = process.env.REACT_APP_COMPANY_EMAIL

export default function Footer() {
  return <>
    <footer className='footer'>
      <A text={COMPANY_PHONE} tel={COMPANY_PHONE} className='footer__text' />
      <A text={COMPANY_EMAIL} mailTo={COMPANY_EMAIL} className='footer__text' />
    </footer>
  </>
}
