import {useDebugState} from 'use-named-state'
import {LoadingButton} from '@mui/lab'
import {Color} from '../../../models/Color'
import {useNavigate} from 'react-router-dom'
import {ReactNode} from "react";

type ButtonVariant = 'text' | 'outlined' | 'contained'
export type ButtonSize = 'small' | 'medium' | 'large'

export enum ButtonStyle {
  CONTAINED,
  OUTLINED,
  RED,
  RED_OUTLINED,
  TEXT,
}

interface LoadingButtonProps {
  buttonStyle?: ButtonStyle
  size?: ButtonSize
  label?: string
  onClick?: () => Promise<any>
  thenAction?: (value: any) => void
  onClickVoid?: () => void
  navigateTo?: string
  disabled?: boolean
  logPath?: string
  icon?: ReactNode
  setErrorMessage?: (value: string) => void
  className?: string
  id?: string
}

export default function Button_({buttonStyle, size, label, onClick, thenAction, onClickVoid, navigateTo, disabled, logPath, icon, setErrorMessage, className, id}: LoadingButtonProps) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useDebugState('isLoading', false)
  let color: Color
  let buttonVariant: ButtonVariant
  if (buttonStyle === ButtonStyle.RED) {
    color = 'error'
    buttonVariant = 'contained'
  } else if (buttonStyle === ButtonStyle.RED_OUTLINED) {
    color = 'error'
    buttonVariant = 'outlined'
  } else if (buttonStyle === ButtonStyle.OUTLINED) {
    color = 'primary'
    buttonVariant = 'outlined'
  } else if (buttonStyle === ButtonStyle.TEXT) {
    color = 'primary'
    buttonVariant = 'text'
  } else {
    color = 'primary'
    buttonVariant = 'contained'
  }
  return <>
    <LoadingButton
      color={color}
      size={size || 'large'}
      variant={buttonVariant}
      loading={isLoading}
      disabled={disabled || false}
      onClick={() => {
        if (onClickVoid) {
          setIsLoading(true)
          onClickVoid()
          setIsLoading(false)
        } else if (onClick) {
          setIsLoading(true)
          onClick()
            .then(r => {
              if (navigateTo) {
                navigate(navigateTo)
              } else {
                thenAction && thenAction(r)
                setIsLoading(false)
              }
            })
            .catch(r => {
              if (setErrorMessage) {
                setErrorMessage(r.response.data.message)
              }
              if (logPath) {
                console.log(`${logPath}: ${JSON.stringify(r)}`)
              }
              setIsLoading(false)
            })
        } else if (navigateTo) {
          navigate(navigateTo)
        }
      }}
      startIcon={icon}
      className={className}
      id={id}
    >
      {label}
    </LoadingButton>
  </>
}