export function getCookieName() {
  return process.env.REACT_APP_TOKEN_COOKIE_NAME
}

export function redirectToUrl() {
  return process.env.REACT_APP_REDIRECT_URL
}

export function getBackendUrl() {
  return process.env.REACT_APP_BACKEND_URL
}

export function getGoogleApiKey() {
  return process.env.REACT_APP_GOOGLE_MAP_API_KEY
}

export function getStripePublicKey() {
  return process.env.REACT_APP_STRIPE_PUBLICK_KEY
}

export function getSeoKeywords() {
  return process.env.REACT_APP_SEO_KEYWORDS
}
