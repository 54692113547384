import './style.css'
import { useDebugState } from 'use-named-state'
import Text_ from '../../../elements/./Text/Text'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { useNavigate } from 'react-router-dom'
import { ORDER_TRACKS__PATH } from '../../../../config/page_paths'

export default function OrderTracks() {
  const navigate = useNavigate()
  const [orderId, setOrderId] = useDebugState<string | undefined>('orderId', undefined)
  const [orderIdValid, setOrderIdValid] = useDebugState<boolean>('orderIdValid', true)

  function handleOnChange(value: string) {
    setOrderIdValid(value.length == 10)
    setOrderId(value)
  }

  function handleOnSearchClick() {
    if (orderIdValid) {
      navigate(`${ORDER_TRACKS__PATH}/${orderId}`)
    }
  }

  return <div className='order_tracks'>
    <div className='order_tracks__fields'>
      <div className='order_tracks__search'>
        <Typography className='order_tracks__title'>Enter 10-digits Order ID</Typography>
        <div className='order_tracks__order_id_and_search_button'>
          <Text_ value={orderId} onChange={handleOnChange} valid={orderIdValid} textSize='small' className='order_tracks__order_id' />
          <IconButton onClick={handleOnSearchClick} className='order_tracks__search_button'>
            <SearchIcon color={(orderIdValid && orderId !== undefined && orderId !== '') ? 'primary' : 'disabled'} fontSize='large' />
          </IconButton>
        </div>
      </div>
    </div>
  </div>
}
