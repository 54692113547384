import Button from "@mui/material/Button";
import {TextField, Typography} from "@mui/material";
import React from "react";
import {getBackendUrl} from "../../../../config/env";
import {Google} from "@mui/icons-material";
import {useDebugState} from "use-named-state";
import {authOtpRequest, authRequest} from "../../../../api/auth/auth_api";
import {AuthCodeRequest, AuthRequest} from "../../../../models/auth/Auth";
import {useUser} from "../../../../pages/Layout/Layout";
import {MuiOtpInput} from "mui-one-time-password-input";
import Title from "./Title";


const OTP_LENGTH = 6

interface Props {
}

export default function UserOrderCreateV2_6_Login(props: Props) {
  const {user, mutateUser} = useUser()
  const [email, setEmail] = useDebugState<string | undefined>('email', undefined)
  const [authButtonDisabled, setAuthButtonDisabled] = useDebugState<boolean>('authButtonDisabled', true)

  function handleOnEmailChange(value: string) {
    setEmail(value)
    if (value !== '') {
      setAuthButtonDisabled(false)
      localStorage.setItem('email', value)
    } else {
      setAuthButtonDisabled(true)
      localStorage.setItem('email', value)
    }
  }

  const [isOptUsed, setIsOptUsed] = useDebugState<boolean>('isOptUsed', false)

  function handleAuthRequest(event: any) {
    event.preventDefault()
    if (!authButtonDisabled) {
      authRequest({'email': email} as AuthRequest)
      setIsOptUsed(true)
    } else {
      console.error('Auth button should be disabled. Set email first')
    }
  }


  const [code, setCode] = useDebugState<string | undefined>('code', undefined)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useDebugState<boolean>('submitButtonDisabled', true)


  const handleSubmitOtp = () => {
    const email = localStorage.getItem('email')
    authOtpRequest({email: email, code: code} as AuthCodeRequest)
      .then((response) => {
        const token = response.token
        if (token) {
          localStorage.setItem('token', token)
          mutateUser && mutateUser()
        } else {
        }
      })
      .catch(e => {
        console.log('AuthOtpView.handleSubmitOtp: Error: ' + JSON.stringify(e))
      })
  }

  const handleOtpChange = (value: string) => {
    setCode(value)
    if (value.length === OTP_LENGTH) {
      setSubmitButtonDisabled(false)
    } else {
      setSubmitButtonDisabled(true)
    }
  }

  // FIXME request name and phone
  return <div style={{
    display: "flex", flexDirection: "column", rowGap: "0.5em",
    paddingTop: "0.25em", paddingBottom: "1.5em",
  }}>
    <Title text="All right, let’s sign in / sign up" className="user_order_create_v2__login__title" />
    <Button style={{margin: "auto"}} size={'large'} variant='contained' color='primary' href={getBackendUrl() + '/login?redirect_to=' + window.location.href}
            startIcon={<Google />}>Sign in with Google</Button>
    {
      isOptUsed
        ? <>
          <Typography style={{margin: "auto"}} variant='subtitle1' component='p' sx={{textAlign: 'center', margin: "auto"}}>Enter One-Time Password to sign in</Typography>
          <MuiOtpInput style={{margin: "auto"}} TextFieldsProps={{type: 'tel'}} value={code} onChange={handleOtpChange} length={OTP_LENGTH} />
          <Button style={{margin: "auto"}} variant='contained' color='primary' disabled={submitButtonDisabled} onClick={handleSubmitOtp}>Sign In</Button>
        </>
        : <>
          <Typography style={{margin: "auto"}} variant='subtitle1'>Enter email to receive a One-Time Password</Typography>
          <TextField style={{margin: "auto"}} label='Email' variant='outlined' onChange={e => handleOnEmailChange(e.target.value)} value={email} InputLabelProps={{shrink: true}}
                     className='login_page__email' />
          <Button style={{margin: "auto"}} size={'large'} variant='contained' color='primary' disabled={authButtonDisabled} onClick={handleAuthRequest}
                  className='login_page__otp_button'>Request One-Time Password</Button>
        </>
    }
  </div>
}
