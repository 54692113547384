import './style.css'
import { MAIN__PATH, USER__AGREEMENTS__PATH, USER__ORDERS__PATH } from '../../../config/page_paths'
import Tab_ from '../../elements/Tab/Tab/Tab'

export const UserTabConfig = {
  'Dashboard': { 'label': 'Dashboard', 'index': 0 },
  'Orders': { 'label': 'My Orders', 'index': 1 },
  'Agreements': { 'label': 'Agreements', 'index': 2 },
}

interface DriverTabProps {
  tabIndex: number
}

export default function UserTab({ tabIndex }: DriverTabProps) {
  return <div className='user_tab'>
    <Tab_
      activeIndex={tabIndex}
      tabConfigs={[
        { label: UserTabConfig.Dashboard.label, navigateTo: MAIN__PATH },
        { label: UserTabConfig.Orders.label, navigateTo: USER__ORDERS__PATH },
        { label: UserTabConfig.Agreements.label, navigateTo: USER__AGREEMENTS__PATH },
      ]} />
  </div>
}
