import './style.css'
import UserAgreementsTable from '../../../../components/agreement/user/UserAgreementsTable/UserAgreements'
import UserTab, { UserTabConfig } from '../../../../components/tab/UserTab/UserTab'

export default function UserAgreementsPage() {
  return <div className='user_agreements_page'>
    <UserTab tabIndex={UserTabConfig.Agreements.index} />
    <UserAgreementsTable />
  </div>
}
