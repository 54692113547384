import './style.css'
import Divider_ from '../../../elements/Divider/Divider'
import Text_ from '../../../elements/Text/Text'
import Tel from '../../../elements/Tel/Tel'
import { BaseUser } from '../../../../models/user/BaseUser'
import AdminUserDriverStatus from '../../admin/AdminUserDriverStatus/AdminUserDriverStatus'
import { toUiUserDriverStatus } from '../../../../utils/user_driver_status_utils'

interface UserCardUserInfoProps {
  user: BaseUser
  name?: string
  setName?: (value: string) => void
  phoneNumber?: string
  setPhoneNumber?: (value: string) => void
  driverStatus?: string
  setDriverStatus?: (value: string) => void
}

export default function UserCardUserInfo({ user, name, setName, phoneNumber, setPhoneNumber, driverStatus, setDriverStatus }: UserCardUserInfoProps) {
  return <>
    <Divider_ text='User info' className='user_card__divider__user_info' />
    <Text_ label='Name' value={name} onChange={setName} className='user_card__name' />
    <Tel label='Phone number' value={phoneNumber} onChange={setPhoneNumber} className='user_card__phone_number' />
    {
      setDriverStatus
        ? <AdminUserDriverStatus value={driverStatus} onChange={setDriverStatus} className='user_card__driver_status' />
        : <Text_ label='Driver status' value={toUiUserDriverStatus(user.driver_status)} className='user_card__driver_status' />
    }
    <Text_ label='Email' value={user.email} className='user_card__email' />
  </>
}
