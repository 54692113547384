import './style.css'
import Text_ from '../../../elements/./Text/Text'
import { packagesToString } from '../../../../utils/package_utils'
import { BaseOrder } from '../../../../models/order/BaseOrder'
import Divider_ from '../../../elements/Divider/Divider'

interface OrderCardPackageInfoProps {
  order: BaseOrder
}

export default function OrderCardPackageInfo({ order }: OrderCardPackageInfoProps) {
  return <>
    <Divider_ text='Package info' className='order_card__divider__package_info' />
    <Text_ label='Packages' value={packagesToString(order.packages)} className='order_card__packages' />
  </>
}
