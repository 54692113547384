import './style.css'
import Text_ from '../../../elements/./Text/Text'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import Divider_ from '../../../elements/Divider/Divider'
import { AdminAgreement } from '../../../../models/agreement/AdminAgreement'

interface AgreementCardBaseInfoProps {
  agreement: AdminAgreement
}

export default function AgreementCardBaseInfo({ agreement }: AgreementCardBaseInfoProps) {
  return <>
    <Divider_ text='Base info' className='agreement_card__divider__base_info' />
    <Text_ label='Create at' value={userTimeZoneDate(agreement.created_at)} className='agreement_card__created_at' />
    <Text_ label='Updated at' value={userTimeZoneDate(agreement.updated_at)} className='agreement_card__updated_at' />
    <Text_ label='User ID' value={agreement.user_id} className='agreement_card__user_id' />
  </>
}
