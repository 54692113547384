import './style.css'
import AdminTab, { AdminTabConfig } from '../../../components/tab/AdminTab/AdminTab'
import AdminVisitsTable from '../../../components/visit/AdminVisitsTable/AdminVisitsTable'

export default function AdminVisitsPage() {
  return <div className='admin_visits_page'>
    <AdminTab tabIndex={AdminTabConfig.Visits.index} />
    <AdminVisitsTable />
  </div>
}
