import { Typography } from '@mui/material'

interface HProps {
  variant: string
  text?: string
  className?: string
}

export default function H({ variant, text, className }: HProps) {
  function buildVariant() {
    if (variant === '1') {
      return 'h1'
    } else if (variant === '2') {
      return 'h2'
    } else if (variant === '3') {
      return 'h3'
    } else if (variant === '4') {
      return 'h4'
    } else if (variant === '5') {
      return 'h5'
    } else {
      return 'h6'
    }
  }

  return <>
    <Typography variant={buildVariant()} className={className}>
      {text}
    </Typography>
  </>
}
