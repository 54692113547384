import TextField from '@material-ui/core/TextField'

interface EmailInputProps {
  label: string
  value?: string
  onChange?: (value: any) => void
  required?: boolean
  className?: string
}

export default function Email({ label, value, onChange, className, required }: EmailInputProps) {
  return <>
    <TextField
      label={label}
      value={value || ''}
      onChange={onChange}
      required={required}
      disabled={onChange === undefined}
      variant={onChange ? 'outlined' : 'filled'}
      InputLabelProps={{ shrink: true }}
      autoComplete='email'
      className={className}
    />
  </>
}
