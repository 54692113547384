import './style.css'
import Button_ from '../../../components/elements/Button/Button'
import { meBecomeDriverCreateAccountLink } from '../../../api/user/user_api'
import { useUser } from '../../Layout/Layout'

export default function UserAccountOnboardingPage() {
  const { mutateUser } = useUser()
  return <div className='user_account_onboarding_page'>
    <Button_
      label='Continue setting up Stripe Account'
      onClick={() =>
        meBecomeDriverCreateAccountLink(window.location.href)
          .then(user => {
            const account_onboarding_url = user.stripe_data?.account_onboarding_url
            if (account_onboarding_url !== undefined) {
              window.location.href = account_onboarding_url
            }
          })
          .then(() => mutateUser && mutateUser())
      }
      logPath='UserAccountOnboardingPage.meBecomeDriverCreateAccountLink'
    />
  </div>
}
