import './style.css'
import {UserRole} from '../../../../models/user/UserRole'
import {meBecomeDriver, meBecomeDriverCreateAccount, meBecomeDriverCreateAccountLink} from '../../../../api/user/user_api'
import Button_, {ButtonStyle} from '../../../elements/Button/Button'
import Divider_ from '../../../elements/Divider/Divider'
import Typography from '@mui/material/Typography'
import {useUser} from '../../../../pages/Layout/Layout'
import {MAIN__PATH} from '../../../../config/page_paths'
import {useNavigate} from 'react-router-dom'
import {Checkbox, FormControlLabel, FormGroup, TextField} from '@mui/material'
import {BaseAgreement} from '../../../../models/agreement/BaseAgreement'
import {getBackendUrl} from '../../../../config/env'
import {useDebugState} from 'use-named-state'
import {useDriverAgreementsActive} from '../../../../api/agreement/driver_agreement_api'
import {UserDriverStatus} from '../../../../models/user/UserDriverStatus'
import Title from "../../../order/user/UserOrderCreateV2/Title";
import Button from "@mui/material/Button";
import {Google} from "@mui/icons-material";
import {MuiOtpInput} from "mui-one-time-password-input";
import React, {useEffect} from "react";
import {authOtpRequest, authRequest} from "../../../../api/auth/auth_api";
import {AuthCodeRequest, AuthRequest} from "../../../../models/auth/Auth";
import Cookies from "js-cookie";

const OTP_LENGTH = 6

export default function UserBecomeDriver() {

  const navigate = useNavigate()
  const [formComplete, setFormComplete] = useDebugState<boolean>('formComplete', false)

  const {data: agreementsPage} = useDriverAgreementsActive()
  const [agreementAcknowledged, setAgreementAcknowledged] = useDebugState<boolean>('agreementAcknowledged', false)

  function onAgreementChange(newValue: boolean) {
    if (!newValue) {
      setAgreementAcknowledged(false)
      setFormComplete(false)
    } else {
      setAgreementAcknowledged(true)
      setFormComplete(true)
    }
  }

  const {user, mutateUser} = useUser()

  useEffect(() => {
    if (user === undefined) {
      const token = Cookies.get('jwt_token')
      if (token) {
        localStorage.setItem('token', token)
        mutateUser && mutateUser()
      }
    }
  }, [user])

  const [email, setEmail] = useDebugState<string | undefined>('email', undefined)
  const [authButtonDisabled, setAuthButtonDisabled] = useDebugState<boolean>('authButtonDisabled', true)

  function handleOnEmailChange(value: string) {
    setEmail(value)
    if (value !== '') {
      setAuthButtonDisabled(false)
      localStorage.setItem('email', value)
    } else {
      setAuthButtonDisabled(true)
      localStorage.setItem('email', value)
    }
  }

  const [isOtpUsed, setIsOtpUsed] = useDebugState<boolean>('isOtpUsed', false)

  function handleAuthRequest(event: any) {
    event.preventDefault()
    if (!authButtonDisabled) {
      authRequest({'email': email} as AuthRequest)
      setIsOtpUsed(true)
    } else {
      console.error('Auth button should be disabled. Set email first')
    }
  }


  const [code, setCode] = useDebugState<string | undefined>('code', undefined)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useDebugState<boolean>('submitButtonDisabled', true)


  const handleSubmitOtp = () => {
    const email = localStorage.getItem('email')
    authOtpRequest({email: email, code: code} as AuthCodeRequest)
      .then((response) => {
        const token = response.token
        if (token) {
          localStorage.setItem('token', token)
          mutateUser && mutateUser()
        } else {
        }
      })
      .catch(e => {
        console.log('AuthOtpView.handleSubmitOtp: Error: ' + JSON.stringify(e))
      })
  }

  const handleOtpChange = (value: string) => {
    setCode(value)
    if (value.length === OTP_LENGTH) {
      setSubmitButtonDisabled(false)
    } else {
      setSubmitButtonDisabled(true)
    }
  }

  function buildContent() {
    if (!user) {
      return <>
        <div style={{
          display: "flex", flexDirection: "column", rowGap: "0.5em",
          paddingTop: "0.25em", paddingBottom: "1.5em",
        }}>
          <Title text="All right, let’s sign in / sign up" className="user_become_driver__title" />
          <Button style={{margin: "auto"}} size={'large'} variant='contained' color='primary' href={getBackendUrl() + '/login?redirect_to=' + window.location.href}
                  startIcon={<Google />}>Sign in with Google</Button>
          {
            isOtpUsed
              ? <>
                <Typography style={{margin: "auto"}} variant='subtitle1' component='p' sx={{textAlign: 'center', margin: "auto"}}>Enter One-Time Password to sign in</Typography>
                <MuiOtpInput style={{margin: "auto"}} TextFieldsProps={{type: 'tel'}} value={code} onChange={handleOtpChange} length={OTP_LENGTH} />
                <Button style={{margin: "auto"}} variant='contained' color='primary' disabled={submitButtonDisabled} onClick={handleSubmitOtp}>Sign In</Button>
              </>
              : <>
                <Typography style={{margin: "auto"}} variant='subtitle1'>Enter email to receive a One-Time Password</Typography>
                <TextField style={{margin: "auto"}} label='Email' variant='outlined' onChange={e => handleOnEmailChange(e.target.value)} value={email} InputLabelProps={{shrink: true}}
                           className='login_page__email' />
                <Button style={{margin: "auto"}} size={'large'} variant='contained' color='primary' disabled={authButtonDisabled} onClick={handleAuthRequest}
                        className='login_page__otp_button'>Request One-Time Password</Button>
              </>
          }
        </div>
      </>
    } else if (user.stripe_data?.account_id === undefined) {
      return <div className='user_become_driver__apply'>
        <Typography className='user_become_driver__apply__description'>Take the opportunity to become an iUjex Driver</Typography>
        <div className='user_become_driver__agreement'>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={agreementAcknowledged} onChange={_ => onAgreementChange(!agreementAcknowledged)} size='small' />}
              label="By clicking 'Apply' I have read and agreed with the following Terms and Conditions:"
              componentsProps={{typography: {fontSize: '0.75em'}}}
            />
          </FormGroup>
          {agreementsPage?.content?.map((agreement: BaseAgreement, i: number) => {
            return <a href={`${getBackendUrl()}/driver/agreements/${agreement?.agreement_id}/file`} style={{fontSize: '0.75em'}} key={i}>{agreement?.name}</a>
          })}
        </div>
        <Button_
          label='Apply'
          disabled={!agreementAcknowledged}
          onClick={() =>
            meBecomeDriverCreateAccount(
              window.location.href,
              agreementsPage?.content?.map(e => e.agreement_id) || []
            ).then(user => {
              const account_onboarding_url = user.stripe_data?.account_onboarding_url
              if (account_onboarding_url !== undefined) {
                window.location.href = account_onboarding_url
              }
            }).then(() => mutateUser && mutateUser())
          }
          logPath='BecomeDriver.meCreateAccount'
          className='user_become_driver__apply__apply'
        />
        <Button_
          label='Not interested'
          buttonStyle={ButtonStyle.RED_OUTLINED}
          onClick={() => meBecomeDriver(false).then(() => navigate(MAIN__PATH))}
          logPath='BecomeDriver.meBecomeDriver?value=false'
          className='user_become_driver__apply__not_interested'
        />
      </div>
    } else if (user?.stripe_data?.account_onboarding_url) {
      return <div className='user_become_driver__continue'>
        <Typography className='user_become_driver__continue__description'>
          Your Stripe account is not fully configured.<br />
          Please complete your Stripe account to become a Driver
        </Typography>
        <Button_
          label='Continue setup'
          onClick={() =>
            meBecomeDriverCreateAccountLink(window.location.href)
              .then(user => {
                const account_onboarding_url = user.stripe_data?.account_onboarding_url
                if (account_onboarding_url !== undefined) {
                  window.location.href = account_onboarding_url
                }
              })
              .then(() => mutateUser && mutateUser())
          }
          logPath='UserAccountOnboardingPage.meCreateAccountLink'
          className='user_become_driver__continue__setup'
        />
      </div>
    }
  }

  return <div className='user_become_driver'>
    {user?.driver_status === undefined &&
        <Divider_ text='Become a Driver' className='user_become_driver__divider__common_info' />}
    {buildContent()}
  </div>
}
