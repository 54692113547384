import './style.css'
import { CircularProgress } from '@mui/material'
import { useUserOrder } from '../../../../api/order/user_order_api'
import OrderShortDetailsWithTimeline from '../../OrderShortDetailsWithTimeline/OrderShortDetailsWithTimeline'
import OrderFilesSwipeableView from '../../OrderFilesSwipeableView/OrderFilesSwipeableView'
import OrderCardPickupInfo from '../.././card_info/OrderCardPickupInfo/OrderCardPickupInfo'
import OrderCardDeliveryInfo from '../../card_info/OrderCardDeliveryInfo/OrderCardDeliveryInfo'
import OrderCardRouteInfo from '../../card_info/OrderCardRouteInfo/OrderCardRouteInfo'
import OrderCardPackageInfo from '../../card_info/OrderCardPackageInfo/OrderCardPackageInfo'
import OrderCardPaymentInfo from '../../card_info/OrderCardPaymentInfo/OrderCardPaymentInfo'
import OrderCardContactInfo from '../../card_info/OrderCardContactInfo/OrderCardContactInfo'
import OrderCardBaseInfo from '../../card_info/OrderCardBaseInfo/OrderCardBaseInfo'
import UserOrderCardDriverInfo from '../../card_info/OrderCardDriverInfo/UserOrderCardDriverInfo'
import Divider_ from '../../../elements/Divider/Divider'
import Map_ from '../../../elements/Map/Map'
import { buildMarkers, buildPolylines } from '../../../elements/Map/utils'

interface UserOrderCardProps {
  orderId: string
}

export default function UserOrderCard({ orderId }: UserOrderCardProps) {
  const { data: order, mutate: mutateOrder } = useUserOrder(orderId)

  return <>
    {order ? <div className='user_order_card__fields'>
        <Divider_ text={`Order overview: ${orderId}`} className='user_order_card__divider__overview' />
        <div className='user_order_card__short_details_with_timeline'>
          <OrderShortDetailsWithTimeline orderId={order.order_id} orderStatus={order.status} userRate={order.user_rate} />
        </div>

        <Map_ polylines={buildPolylines(order)} markers={buildMarkers([order])} className='user_order_card__map' />
        {order.files && <OrderFilesSwipeableView orderId={order.order_id} files={order.files} />}

        <UserOrderCardDriverInfo order={order} />
        <OrderCardPickupInfo order={order} />
        <OrderCardDeliveryInfo order={order} />
        <OrderCardRouteInfo order={order} />
        <OrderCardPackageInfo order={order} />
        <OrderCardPaymentInfo order={order} />
        <OrderCardContactInfo order={order} />
        <OrderCardBaseInfo order={order} />
      </div>
      : <CircularProgress />
    }
  </>
}
