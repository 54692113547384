import './style.css'
import { useParams } from 'react-router-dom'
import AdminTab, { AdminTabConfig } from '../../../components/tab/AdminTab/AdminTab'
import AdminOrderCard from '../../../components/order/admin/AdminOrderCard/AdminOrderCard'

export default function AdminOrderCardPage() {
  const { orderId } = useParams()
  return <div className='admin_order_card_page'>
    <AdminTab tabIndex={AdminTabConfig.Orders.index} />
    <AdminOrderCard orderId={orderId as string} />
  </div>
}
