export function userTimeZoneDate(inputDateString?: string, timezone?: string): string {
  if (inputDateString) {
    return new Date(inputDateString)
      .toLocaleString(undefined, {
        timeZone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      .replace(/\b([ap])m\b/g, match => match.toUpperCase())
  } else {
    return ''
  }
}
