import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { getCookieName } from '../../config/env'
import { LOGIN__PATH } from '../../config/page_paths'
import { useUser } from '../Layout/Layout'

export default function LogoutPage() {
  const { mutateUser } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    Cookies.remove(getCookieName() || '')
    localStorage.removeItem('token')
    mutateUser && mutateUser()
    navigate(LOGIN__PATH)
  })

  return <></>
}
