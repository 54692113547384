import './style.css'
import {useParams} from "react-router-dom"
import UserOrderCreateV2 from "../../../components/order/user/UserOrderCreateV2/UserOrderCreateV2"

export default function UserOrderCreateV2Page() {
  const {orderId} = useParams()
  return <div className='user_order_create_v2_page'>
    <UserOrderCreateV2 orderId={orderId} />
  </div>
}
