import useSWR, {SWRResponse} from 'swr'
import {AdminRate, AdminRateCreateRequest, AdminRateCalculateRequest, AdminRateUpdateRequest} from '../../models/rate/AdminRate'
import {http} from '../http'
import {PageResponse} from '../../models/Page'
import {usePageable} from '../../utils/swr_utils'

export const adminRateCreate = (request: AdminRateCreateRequest): Promise<AdminRate> => {
  return http.postData<AdminRateCreateRequest, AdminRate>('/admin/rates', request)
}

export const useAdminRate = (rateId: string): SWRResponse<AdminRate> => {
  return useSWR<AdminRate>([`/admin/rates/${rateId}`])
}

export const useAdminRates = (page?: number, pageSize?: number): SWRResponse<PageResponse<AdminRate>> => {
  return usePageable('/admin/rates/search', {page: page, page_size: pageSize})
}

export const adminRateCalculate = (request: AdminRateCalculateRequest): Promise<number> => {
  return http.postData<AdminRateCalculateRequest, number>('/admin/rates/calculate', request)
}

export const adminRateUpdate = (rateId: string, request: AdminRateUpdateRequest): Promise<AdminRate> => {
  return http.putData<AdminRateUpdateRequest, AdminRate>(`/admin/rates/${rateId}`, request)
}

export const adminRateDelete = (rateId: string): Promise<void> => {
  return http.delete(`/admin/rates/${rateId}`)
}
