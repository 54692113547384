import './style.css'
import Text_ from '../../../elements/./Text/Text'
import Divider_ from '../../../elements/Divider/Divider'
import { AdminAgreement } from '../../../../models/agreement/AdminAgreement'

interface AgreementCardStatusInfoProps {
  agreement: AdminAgreement
}

export default function AgreementCardStatusInfo({ agreement }: AgreementCardStatusInfoProps) {
  return <>
    <Divider_ text='Staus info' className='agreement_card__divider__status_info' />
    <Text_ label='Status' value={agreement.status} className='agreement_card__status' />
    <Text_ label='User Role' value={agreement.user_role} className='agreement_card__user_role' />
  </>
}
