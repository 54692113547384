import { http } from '../http'
import { AuthCodeRequest, AuthCodeResponse, AuthRequest } from '../../models/auth/Auth'

export const authRequest = (request: AuthRequest): Promise<void> => {
  return http.post('/auth', request)
}

export const authOtpRequest = (request: AuthCodeRequest): Promise<AuthCodeResponse> => {
  return http.postData<AuthCodeRequest, AuthCodeResponse>('/auth/code', request)
}
