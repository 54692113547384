import './style.css'
import Text_ from '../../../elements/./Text/Text'
import Divider_ from '../../../elements/Divider/Divider'
import { AdminAgreement } from '../../../../models/agreement/AdminAgreement'

interface AgreementCardFileInfoProps {
  agreement: AdminAgreement
}

export default function AgreementCardFileInfo({ agreement }: AgreementCardFileInfoProps) {
  return <>
    <Divider_ text='File info' className='agreement_card__divider__file_info' />
    <Text_ label='File Name' value={agreement.document_metadata.file_name} className='agreement_card__file_name' />
    <Text_ label='MIME Type' value={agreement.document_metadata.mime_type} className='agreement_card__mime_type' />
    <Text_ label='Extension' value={agreement.document_metadata.extension} className='agreement_card__extension' />
    <Text_ label='Size Bytes' value={agreement.document_metadata.size_bytes} className='agreement_card__size_bytes' />
  </>
}
