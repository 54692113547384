import './style.css'
import { useDebugState } from 'use-named-state'
import { useDriverRoutes } from '../../../../api/route/driver_route_api'
import Table_ from '../../../elements/Table/Table'
import { DRIVER__ROUTE_CREATE__PATH } from '../../../../config/page_paths'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import { DATETIME_WIDTH, DISTANCE_MI_WIDTH, SHORT_ADDRESS_WIDTH } from '../../../../config/table_witdth_config'

export default function DriverRoutesTable() {
  const [page, setPage] = useDebugState('page', 0)
  const [pageSize, setPageSize] = useDebugState('pageSize', 10)
  const { data: routesPage } = useDriverRoutes(page, pageSize)
  return <>
    <Table_
      entitiesPage={routesPage}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onRowClickNavigateCommonPath={'/driver/routes/'}
      onCreateNavigatePath={DRIVER__ROUTE_CREATE__PATH}
      config={
        {
          idKey: 'route_id',
          columns: [
            { header: 'From', entityPath: ['start_from', 'short_address'], width: SHORT_ADDRESS_WIDTH },
            { header: 'To', entityPath: ['end_to', 'short_address'], width: SHORT_ADDRESS_WIDTH },
            { header: 'Start at', entityPath: ['start_at'], processor: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
            { header: 'End at', entityPath: ['end_at'], processor: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
            { header: 'Distance, mi', entityPath: ['distance_data', 'distance_mi'], width: DISTANCE_MI_WIDTH },
          ],
        }
      }
    />
  </>
}
