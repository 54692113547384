import './style.css'
import DriverOrdersTable from '../../../components/order/driver/DriverOrdersTable/DriverOrdersTable'
import DriverTab, { DriverTabConfig } from '../../../components/tab/DriverTab/DriverTab'

export default function DriverOrdersPage() {
  return <div className='driver_orders_page'>
    <DriverTab tabIndex={DriverTabConfig.Orders.index} />
    <DriverOrdersTable />
  </div>
}
