import useSWR, { SWRResponse } from 'swr'
import { http } from '../http'
import { DriverOrder, DriverOrderDeliverRequest, DriverOrderPickupRequest, DriverOrderTable } from '../../models/order/DriverOrder'
import { PageResponse } from '../../models/Page'
import { useBaseOrders, usePageable } from '../../utils/swr_utils'
import { OrderStatus } from '../../models/order/OrderStatus'

export const useDriverOrder = (orderId: string): SWRResponse<DriverOrder> => {
  return useSWR<DriverOrder>([`/driver/orders/${orderId}`])
}

export const useDriverOrders = (page?: number, pageSize?: number, orderStatus?: OrderStatus): SWRResponse<PageResponse<DriverOrderTable>> => {
  return useBaseOrders('/driver/orders/search', page, pageSize, orderStatus)
}

export const useDriverOrdersAvailable = (page?: number, pageSize?: number): SWRResponse<PageResponse<DriverOrder>> => {
  return usePageable('/driver/orders/search-available', { page: page, page_size: pageSize })
}

export const useDriverOrdersActive = (): SWRResponse<PageResponse<DriverOrder>> => {
  return usePageable(`/driver/orders/search-active`, {page: 0, page_size: 10})
}

export const driverOrderAssign = (orderId: string, returnUrl: string): Promise<DriverOrder> => {
  return http.putData<void, DriverOrder>(`/driver/orders/${orderId}/assign?return_url=${returnUrl}`)
}

export const driverOrderPickup = (orderId: string, request: DriverOrderPickupRequest, files: FileList): Promise<DriverOrder> => {
  return http.putMultipartData(`/driver/orders/${orderId}/pickup`, request, files)
}

export const driverOrderDeliver = (orderId: string, request: DriverOrderDeliverRequest, files: FileList): Promise<DriverOrder> => {
  return http.putMultipartData(`/driver/orders/${orderId}/deliver`, request, files)
}
