import {http} from '../http'
import {UserOrderCreateV2_AddressAndPackagesRequest, UserOrderUpdateV2_ConfirmAndAgreementRequest, UserOrderUpdateV2_DetailsAndPhotosRequest, UserOrderV2} from "../../models/order/UserOrderV2";


export const orderGetV2 = (orderId: string): Promise<UserOrderV2> => {
  return http.getData(`/v2/orders/${orderId}`)
}

export function orderCreateV2_draft(request: UserOrderCreateV2_AddressAndPackagesRequest): Promise<UserOrderV2> {
  return http.postData('/v2/orders/draft', request)
}

export function orderUpdateV2_pickupAndDeliveryDetailsAndPhotos(orderId: string, request: UserOrderUpdateV2_DetailsAndPhotosRequest, files?: FileList): Promise<UserOrderV2> {
  return http.putMultipartData(`/v2/orders/${orderId}/details-and-photos`, request, files)
}

export const orderGetV2_confirmAndHold = (orderId: string, returnUrl: string, request: UserOrderUpdateV2_ConfirmAndAgreementRequest): Promise<UserOrderV2> => {
  return http.putData(`/v2/orders/${orderId}/hold?return_url=${returnUrl}`, request)
}

export const orderGetV2_setUser = (orderId: string): Promise<UserOrderV2> => {
  return http.putData(`/v2/orders/${orderId}/set-user`)
}
