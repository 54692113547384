import useSWR, { SWRResponse } from 'swr'
import { PageRequest, PageResponse } from '../../models/Page'
import { usePageable } from '../../utils/swr_utils'
import { BaseAgreement } from '../../models/agreement/BaseAgreement'

export const useUserAgreementActive = (agreementId: string): SWRResponse<BaseAgreement> => {
  return useSWR<BaseAgreement>([`/agreements/${agreementId}/active`])
}

export const useUserAgreementsActive = (page?: number, pageSize?: number): SWRResponse<PageResponse<BaseAgreement>> => {
  const request = {
    page: page,
    page_size: pageSize,
  } as PageRequest<any>
  return usePageable('/agreements/search-active', request)
}
