import './style.css'
import { useParams } from 'react-router-dom'
import DriverAgreementCard from '../../../../components/agreement/driver/DriverAgreementCard/DriverAgreementCard'
import DriverTab, { DriverTabConfig } from '../../../../components/tab/DriverTab/DriverTab'

export default function DriverAgreementCardPage() {
  const { agreementId } = useParams()
  return <div className='driver_agreement_card_page'>
    <DriverTab tabIndex={DriverTabConfig.Agreements.index} />
    <DriverAgreementCard agreementId={agreementId as string} />
  </div>
}
