import './style.css'
import { RateType } from '../../../models/rate/AdminRate'
import { toUiRateType } from '../../../utils/rate_type_utils'
import SelectField_ from '../../elements/SelectField/SelectField'


interface Props {
  value?: string
  onChange: (value: any) => void
  className?: string
}

export default function AdminRateType({ value, onChange, className }: Props) {

  function buildValues() {
    const values: string[] = []
    Object.keys(RateType).forEach(key => values.push(toUiRateType(key as RateType)))
    return values
  }

  return <>
    <SelectField_
      label='Rate type'
      value={value}
      values={buildValues()}
      onChange={(newValue) => onChange(newValue)}
      className={className}
    />
  </>
}
