import './style.css'
import Text_ from '../../../elements/./Text/Text'
import { AdminOrder } from '../../../../models/order/AdminOrder'
import Tel from '../../../elements/./Tel/Tel'
import Divider_ from '../../../elements/Divider/Divider'

interface AdminOrderCardDriverInfoProps {
  order: AdminOrder
}

export default function AdminOrderCardDriverInfo({ order }: AdminOrderCardDriverInfoProps) {
  return <>
    <Divider_ text='Driver info' className='order_card__divider__driver_info' />
    <Text_ label='Driver name' value={order.driver_name} className='order_card__driver_name' />
    <Tel label='Driver phone number' value={order.driver_phone_number} className='order_card__driver_phone_number' />
    <Text_ label='Driver ID' value={order.driver_id} className='order_card__driver_id' />
    <Text_ label='Internal comment' value={order.internal_comment} className='order_card__internal_comment' />
  </>
}
