import './style.css'
import Text_ from '../../../elements/./Text/Text'
import { BaseOrder } from '../../../../models/order/BaseOrder'
import Divider_ from '../../../elements/Divider/Divider'
import { userTimeZoneDate } from '../../../../utils/time_utils'

interface OrderCardDeliveryInfoProps {
  order: BaseOrder
}

export default function OrderCardDeliveryInfo({ order }: OrderCardDeliveryInfoProps) {
  return <>
    <Divider_ text='Delivery info' className='order_card__divider__delivery_info' />
    <Text_ label='Delivery to' value={order.delivery_to.full_address} className='order_card__delivery_to' />
    <Text_ label='Delivery contactless' value={order.delivery_contactless} className='order_card__delivery_contactless' />
    <Text_ label='Delivery Phone number' value={order.delivery_phone_number} className='order_card__delivery_phone_number' />
    <Text_ label='Delivery at, calculated' value={userTimeZoneDate(order.delivery_at_calculated)} className='order_card__delivery_at_calculated' />
    <Text_ label='Delivery at, input' value={userTimeZoneDate(order.delivery_at_input)} className='order_card__delivery_at_input' />
  </>
}
