import './style.css'
import { UserOrder } from '../../../../models/order/UserOrder'
import Text_ from '../../../elements/./Text/Text'
import Tel from '../../../elements/./Tel/Tel'
import Divider_ from '../../../elements/Divider/Divider'

interface UserOrderCardDriverInfoProps {
  order: UserOrder
}

export default function UserOrderCardDriverInfo({ order }: UserOrderCardDriverInfoProps) {
  return <>
    <Divider_ text='Driver info' className='order_card__divider__driver_info' />
    <Text_ label='Driver name' value={order.driver_name} className='order_card__driver_name' />
    <Tel label='Driver phone number' value={order.driver_phone_number} className='order_card__driver_phone_number' />
  </>
}
