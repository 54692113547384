import { LoadingButton } from '@mui/lab'

interface SubmitProps {
  label?: string
  onClick: (value: any) => void
  isUploading?: boolean
  className?: string
  disabled?: boolean
}

export default function Submit_({ label, onClick, isUploading, className, disabled }: SubmitProps) {
  return <div className={className}>
    <LoadingButton
      fullWidth
      size='large'
      onClick={onClick}
      variant='contained'
      loading={isUploading || false}
      disabled={disabled === undefined ? false : disabled}
    >
      {label || 'Submit'}
    </LoadingButton>
  </div>
}
