import './style.css'
import { useDebugState } from 'use-named-state'
import { useAdminRoutes } from '../../../../api/route/admin_route_api'
import Table_ from '../../../elements/Table/Table'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import { DATETIME_WIDTH, DISTANCE_MI_WIDTH, ID_WIDTH } from '../../../../config/table_witdth_config'

export default function AdminRoutesTable() {
  const [page, setPage] = useDebugState('page', 0)
  const [pageSize, setPageSize] = useDebugState('pageSize', 10)
  const { data: routesPage } = useAdminRoutes(page, pageSize)
  return <>
    <Table_
      entitiesPage={routesPage}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onRowClickNavigateCommonPath='/admin/routes/'
      config={
        {
          idKey: 'route_id',
          columns: [
            { header: 'User ID', entityPath: ['user_id'], width: ID_WIDTH },
            { header: 'Start at', entityPath: ['start_at'], processor: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
            { header: 'End at', entityPath: ['end_at'], processor: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
            { header: 'Distance, mi', entityPath: ['distance_data', 'distance_mi'], width: DISTANCE_MI_WIDTH },
            { header: 'Route ID', entityPath: ['route_id'], width: ID_WIDTH },
          ],
        }
      }
    />
  </>
}
