import useSWR, { SWRResponse } from 'swr'
import { UserOrder, UserOrderTable } from '../../models/order/UserOrder'
import { http } from '../http'
import { PageResponse } from '../../models/Page'
import { useBaseOrders } from '../../utils/swr_utils'
import { OrderStatus } from '../../models/order/OrderStatus'


export const useUserOrder = (orderId: string): SWRResponse<UserOrder> => {
  return useSWR<UserOrder>([`/orders/${orderId}`])
}

export const useUserOrderCurrent = (): SWRResponse<UserOrder | undefined> => {
  return useSWR<UserOrder | undefined>(['/orders/current'])
}

export const useUserOrders = (page?: number, pageSize?: number, orderStatus?: OrderStatus): SWRResponse<PageResponse<UserOrderTable>> => {
  return useBaseOrders('/orders/search', page, pageSize, orderStatus)
}

export async function userOrderFileDownload(orderId: string, fileId: string): Promise<string | undefined> {
  return http.getFile(`/orders/${orderId}/files/${fileId}`)
}

export const userOrderAccept = (orderId: string, returnUrl: string): Promise<UserOrder> => {
  return http.putData<void, UserOrder>(`/orders/${orderId}/accept?return_url=${returnUrl}`)
}

export const userOrderReject = (orderId: string): Promise<UserOrder> => {
  return http.putData<void, UserOrder>(`/orders/${orderId}/reject`)
}
