import './style.css'
import { BaseOrder } from '../../../../models/order/BaseOrder'
import Text_ from '../../../elements/./Text/Text'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import Divider_ from '../../../elements/Divider/Divider'
import { toUiOrderStatus } from '../../../../utils/order_status_utils'

interface OrderCardBaseInfoProps {
  order: BaseOrder
}

export default function OrderCardBaseInfo({ order }: OrderCardBaseInfoProps) {
  return <>
    <Divider_ text='Base info' className='order_card__divider__base_info' />
    <Text_ label='Create at' value={userTimeZoneDate(order.created_at)} className='order_card__created_at' />
    <Text_ label='Updated at' value={userTimeZoneDate(order.updated_at)} className='order_card__updated_at' />
    <Text_ label='User ID' value={order.user_id} className='order_card__user_id' />
    <Text_ label='Status' value={toUiOrderStatus(order.status)} className='order_card__status' />
  </>
}
