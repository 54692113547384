import './style.css'
import { useParams } from 'react-router-dom'
import AdminUserCard from '../../../components/user/admin/AdminUserCard/AdminUserCard'
import AdminTab, { AdminTabConfig } from '../../../components/tab/AdminTab/AdminTab'

export default function AdminUserCardPage() {
  const { userId } = useParams()
  return <div className='admin_user_card_page'>
    <AdminTab tabIndex={AdminTabConfig.Users.index} />
    <AdminUserCard userId={userId as string} />
  </div>
}
