import './style.css'
import { CircularProgress } from '@mui/material'
import { useDriverOrder } from '../../../../api/order/driver_order_api'
import OrderShortDetailsWithTimeline from '../../OrderShortDetailsWithTimeline/OrderShortDetailsWithTimeline'
import { DriverOrderActions } from '../DriverOrderActions/DriverOrderActions'
import OrderFilesSwipeableView from '../../OrderFilesSwipeableView/OrderFilesSwipeableView'
import OrderCardPickupInfo from '../.././card_info/OrderCardPickupInfo/OrderCardPickupInfo'
import OrderCardDeliveryInfo from '../../card_info/OrderCardDeliveryInfo/OrderCardDeliveryInfo'
import OrderCardRouteInfo from '../../card_info/OrderCardRouteInfo/OrderCardRouteInfo'
import OrderCardPackageInfo from '../../card_info/OrderCardPackageInfo/OrderCardPackageInfo'
import OrderCardContactInfo from '../../card_info/OrderCardContactInfo/OrderCardContactInfo'
import OrderCardBaseInfo from '../../card_info/OrderCardBaseInfo/OrderCardBaseInfo'
import DriverOrderCardDriverInfo from '../../card_info/OrderCardDriverInfo/DriverOrderCardDriverInfo'
import Divider_ from '../../../elements/Divider/Divider'

interface DriverOrderCardProps {
  orderId: string
}

export default function DriverOrderCard({ orderId }: DriverOrderCardProps) {
  const { data: order, mutate: mutateOrder } = useDriverOrder(orderId)
  return <>
    {order ? <>
        <Divider_ text='Order overview' className='driver_order_card__divider__overview' />

        <div className='driver_order_card__details'>
          <OrderShortDetailsWithTimeline orderId={order.order_id} orderStatus={order.status} driverRate={order.driver_rate} driverExtraMiles={order.driver_extra_miles} />
          <DriverOrderActions order={order} mutateOrder={mutateOrder} orderId={order.order_id} orderStatus={order.status} />
          {order.files && <OrderFilesSwipeableView orderId={order.order_id} files={order.files} />}
        </div>

        <div className='driver_order_card__fields'>
          <DriverOrderCardDriverInfo order={order} />
          <OrderCardPickupInfo order={order} />
          <OrderCardDeliveryInfo order={order} />
          <OrderCardRouteInfo order={order} />
          <OrderCardPackageInfo order={order} />
          <OrderCardContactInfo order={order} />
          <OrderCardBaseInfo order={order} />
        </div>
      </>
      : <CircularProgress />
    }
  </>
}
