import './style.css'
import Divider_ from '../../../elements/Divider/Divider'
import Text_ from '../../../elements/Text/Text'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import { BaseRoute } from '../../../../models/route/BaseRoute'

interface RouteCardBasicInfoProps {
  route: BaseRoute
}

export default function RouteCardBasicInfo({ route }: RouteCardBasicInfoProps) {
  return <>
    <Divider_ text='Basic info' className='route_card__divider__basic_info' />
    <Text_ label='Route ID' value={route.route_id} className='route_card__route_id' />
    <Text_ label='User ID' value={route.user_id} className='route_card__user_id' />
    <Text_ label='Created at' value={userTimeZoneDate(route.created_at)} className='route_card__created_at' />
    <Text_ label='Updated at' value={userTimeZoneDate(route.updated_at)} className='route_card__updated_at' />
    <Text_ label='Buffer, mi' value={route.buffer_distance_miles} className='route_card__buffer_distance_miles' />
  </>
}
