import './style.css'
import AdminRateCreate from '../../../components/rate/AdminRateCreate/AdminRateCreate'
import AdminTab, { AdminTabConfig } from '../../../components/tab/AdminTab/AdminTab'

export default function AdminRateCreatePage() {
  return <div className='admin_rate_create_page'>
    <AdminTab tabIndex={AdminTabConfig.Rates.index} />
    <AdminRateCreate />
  </div>
}
