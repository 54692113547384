import './style.css'
import { adminUserStripeConnectAccountDelete, adminUserUpdate, useAdminUser } from '../../../../api/user/admin_user_api'
import { CircularProgress } from '@mui/material'
import { useDebugState } from 'use-named-state'
import { useEffect } from 'react'
import { AdminUser, AdminUserUpdateRequest } from '../../../../models/user/AdminUsers'
import Button_, { ButtonStyle } from '../../../elements/Button/Button'
import UserCardPaymentInfo from '../../card_info/UserCardPaymentInfo/UserCardPaymentInfo'
import UserCardBasicInfo from '../../card_info/UserCardBasicInfo/UserCardBasicInfo'
import UserCardUserInfo from '../../card_info/UserCardUserInfo/UserCardUserInfo'
import AdminUserDriverActionButtons from '../AdminUserDriverActionButtons/AdminUserDriverActionButtons'
import { fromUiUserDriverStatus, toUiUserDriverStatus } from '../../../../utils/user_driver_status_utils'
import { fromUiUserRole, toUiUserRole } from '../../../../utils/user_role_utils'

interface AdminUserProps {
  userId: string
}

export default function AdminUserCard({ userId }: AdminUserProps) {
  const { data: user, mutate: mutateUser } = useAdminUser(userId)
  const [name, setName] = useDebugState<string | undefined>('name', undefined)
  const [phoneNumber, setPhoneNumber] = useDebugState<string | undefined>('phoneNumber', undefined)
  const [driverStatus, setDriverStatus] = useDebugState<string | undefined>('driverStatus', undefined)
  const [selectedRole, setSelectedRole] = useDebugState<string | undefined>('selectedRole', undefined)

  function isChanged(): boolean {
    return user?.name !== name
      || user?.phone_number !== phoneNumber
      || toUiUserDriverStatus(user?.driver_status) !== driverStatus
      || toUiUserRole(user?.selected_role) !== selectedRole
  }

  useEffect(() => {
    if (user) {
      setName(user.name)
      setPhoneNumber(user.phone_number)
      setDriverStatus(toUiUserDriverStatus(user.driver_status))
      setSelectedRole(toUiUserRole(user.selected_role))
    }
  }, [user])

  function handleSaveClick(): Promise<AdminUser | undefined> {
    const request = {
      name: name,
      phone_number: phoneNumber,
      driver_status: fromUiUserDriverStatus(driverStatus),
      selected_role: fromUiUserRole(selectedRole),
    } as AdminUserUpdateRequest
    return adminUserUpdate(userId, request)
      .then(_ => mutateUser())
  }

  return <>
    {user ? <div className='admin_user_card__fields'>
        <UserCardUserInfo user={user} name={name} setName={setName} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} driverStatus={driverStatus} setDriverStatus={setDriverStatus} />
        <AdminUserDriverActionButtons user={user} mutateUser={mutateUser} />
        <UserCardPaymentInfo user={user} />
        <UserCardBasicInfo user={user} selectedRole={selectedRole} setSelectedRole={setSelectedRole} />

        <Button_ label='Save' disabled={!isChanged()} onClick={handleSaveClick} logPath='AdminUserCard.handleSaveClick.adminUpdateUser' className='admin_user_card__save' />
        {user.stripe_data?.account_id !== null && user.stripe_data?.account_id !== undefined && <>
            <Button_ label='Delete Stripe Connect account'
                     buttonStyle={ButtonStyle.RED}
                     onClick={() => adminUserStripeConnectAccountDelete(userId).then(() => mutateUser())}
                     logPath='AdminUserCard.adminUserStripeConnectAccountDelete' className='admin_user_card__delete_stripe_connect_account' />
        </>}
      </div>
      : <CircularProgress />
    }
  </>
}
