import './style.css'
import Divider_ from '../../../elements/Divider/Divider'
import AgreementCardDocumentInfo from '../../card_info/AgreementCardDocumentInfo/AgreementCardDocumentInfo'
import { CircularProgress } from '@mui/material'
import { useDriverAgreementActive } from '../../../../api/agreement/driver_agreement_api'
import AgreementCardDocumentView from '../../card_info/AgreementCardDocumentView/AgreementCardDocumentView'

interface DriverAgreementCard {
  agreementId: string
}

export default function DriverAgreementCard({ agreementId }: DriverAgreementCard) {
  const { data: agreement } = useDriverAgreementActive(agreementId)
  return <>
    {agreement ?
      <div className='driver_agreement_card__fields'>
        <Divider_ text={`Agreement overview: ${agreementId}`} className='driver_agreement_card__divider__overview' />
        <AgreementCardDocumentInfo agreement={agreement} />
        <AgreementCardDocumentView agreement={agreement} />
      </div>
      : <CircularProgress />}
  </>
}
