import './style.css'
import { BaseRoute } from '../../../../models/route/BaseRoute'
import Divider_ from '../../../elements/Divider/Divider'
import Table_ from '../../../elements/Table/Table'
import { userTimeZoneDate } from '../../../../utils/time_utils'

interface RouteCardStopsDetailsProps {
  route: BaseRoute
}

export default function RouteCardStopsDetails({ route }: RouteCardStopsDetailsProps) {
  return <>
    <Divider_ text='Stops details' className='route_card__divider__stops_details' />
    <Table_
      entities={route.routes}
      config={
        {
          idKey: 'timestamp_utc',
          columns: [
            { header: 'Address', entityPath: ['address', 'full_address'] },
            { header: 'Date', entityPath: ['datetime'], processor: (value: string) => userTimeZoneDate(value) },
          ],
        }
      }
    />
  </>
}
