import useSWR, { SWRResponse } from 'swr'
import { PageRequest, PageResponse } from '../models/Page'
import { http } from '../api/http'
import { OrderStatus } from '../models/order/OrderStatus'
import { BaseOrderFilter } from '../models/order/BaseOrder'

export function usePageable<Req, Res>(url: string, request: PageRequest<Req>): SWRResponse<PageResponse<Res>> {
  return useSWR<PageResponse<Res>>([url, request], () => http.postData<PageRequest<Req>, PageResponse<Res>>(url, request))
}


export function useBaseOrders<Res>(url: string, page?: number, pageSize?: number, orderStatus?: OrderStatus): SWRResponse<PageResponse<Res>> {
  const request = { page: page, page_size: pageSize } as PageRequest<BaseOrderFilter>
  if (orderStatus) {
    request.filter = { order_status: orderStatus } as BaseOrderFilter
  }
  return usePageable(url, request)
}