import './style.css'
import { driverOrderAssign, useDriverOrdersActive, useDriverOrdersAvailable } from '../../../../api/order/driver_order_api'
import Map_, { SelectedMarker } from '../../../elements/Map/Map'
import { DriverOrder, DriverOrderMap } from '../../../../models/order/DriverOrder'
import { useDriverRouteCurrent } from '../../../../api/route/driver_route_api'
import { Marker } from '../../../elements/Map/Marker'
import { MarkerType } from '../../../elements/Map/MarkerType'
import { Typography } from '@material-ui/core'
import { packagesToString } from '../../../../utils/package_utils'
import { DRIVER__ORDERS__PATH } from '../../../../config/page_paths'
import { useNavigate } from 'react-router-dom'
import { useDebugState } from 'use-named-state'
import Button from '@mui/material/Button'
import { buildEncodedPolygons, buildEncodedPolylines, buildMarkers } from '../../../elements/Map/utils'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'

interface OrderWindow {
  order: DriverOrderMap
}

interface OrderAvailableWindow extends OrderWindow {
  isAssigned: boolean
}

export default function DriverMap() {
  const navigate = useNavigate()

  const { data: route } = useDriverRouteCurrent()
  const { data: ordersAvailablePage } = useDriverOrdersAvailable()
  const { data: ordersActivePage, mutate: mutateOrdersActivePage } = useDriverOrdersActive()
  const [selectedMarker, setSelectedMarker] = useDebugState<SelectedMarker | undefined>('selectedMarker', undefined)
  const [openAcknowledgeDialog, setOpenAcknowledgeDialog] = useDebugState<boolean>('open', false)

  function typography(text: string) {
    return <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>{text}</Typography>
  }

  function renderMarkerWindow(marker: Marker) {
    const order = marker.payload.order
    if (marker.type === MarkerType.AVAILABLE_ORDER) {
      const isAssigned = (marker.payload as OrderAvailableWindow).isAssigned
      return <>
        {typography(`ID: ${order.order_id}`)}
        {typography(`Rate: $${order.driver_rate}`)}
        {typography(`Extra miles: ${order.driver_extra_miles}`)}
        {typography(`From: ${order.pickup_from.short_address}`)}
        {typography(`To: ${order.delivery_to.short_address}`)}
        {typography(`Packages: ${packagesToString(order.packages)}`)}
        {!isAssigned && <Button onClick={() => setOpenAcknowledgeDialog(true)}>Assign to me</Button>}
      </>
    } else if (marker.type === MarkerType.CURRENT_ORDER) {
      return <>
        {typography(`ID: ${order.order_id}`)}
        {typography(`From: ${order.pickup_from.short_address}`)}
        {typography(`To: ${order.delivery_to.short_address}`)}
        {typography(`Packages: ${packagesToString(order.packages)}`)}
        <Button onClick={() => navigate(`${DRIVER__ORDERS__PATH}/${order.order_id}`)}>Details</Button>
      </>
    }
  }

  return <>
    <Dialog open={openAcknowledgeDialog} onClose={() => setOpenAcknowledgeDialog(false)}>
      <DialogTitle>Order Assignment</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to assign the Order?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (selectedMarker) {
              driverOrderAssign(selectedMarker.entityId, window.location.href)
                .then(() => navigate(DRIVER__ORDERS__PATH))
            }
          }}
        >
          Assign
        </Button>
        <Button color='error' onClick={() => setOpenAcknowledgeDialog(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
    {ordersAvailablePage?.content && <>
        <Map_
            polylines={buildEncodedPolylines(
              route?.distance_data.encoded_polyline,
              ordersAvailablePage?.content?.filter((order: DriverOrder) => order.order_id == selectedMarker?.entityId)?.map((order: DriverOrder) => order.distance_data.encoded_polyline),
              ordersActivePage?.content?.map((order: DriverOrder) => order.distance_data.encoded_polyline),
            )}
            polygons={route && buildEncodedPolygons([route.buffered_route])}
            markers={buildMarkers(ordersActivePage?.content, ordersAvailablePage.content)}
            renderMarkerWindow={renderMarkerWindow}
            className='driver_map'
            selectedMarker={selectedMarker}
            setSelectedMarker={setSelectedMarker}
        />
    </>}
  </>
}
