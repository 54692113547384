import './style.css'
import Text_ from '../../../elements/./Text/Text'
import { BaseOrder } from '../../../../models/order/BaseOrder'
import Divider_ from '../../../elements/Divider/Divider'
import { userTimeZoneDate } from '../../../../utils/time_utils'

interface OrderCardPickupInfoProps {
  order: BaseOrder
}

export default function OrderCardPickupInfo({ order }: OrderCardPickupInfoProps) {
  return <>
    <Divider_ text='Pickup info' className='order_card__divider__pickup_info' />
    <Text_ label='Pickup from' value={order.pickup_from.full_address} className='order_card__pickup_from' />
    <Text_ label='Pickup contactless' value={order.pickup_contactless} className='order_card__pickup_contactless' />
    <Text_ label='Pickup Phone number' value={order.pickup_phone_number} className='order_card__pickup_phone_number' />
    <Text_ label='Pickup at, calculated' value={userTimeZoneDate(order.pickup_at_calculated)} className='order_card__pickup_at_calculated' />
    <Text_ label='Pickup at, input' value={userTimeZoneDate(order.pickup_at_input)} className='order_card__pickup_at_input' />
  </>
}
