import './style.css'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js'
import UserStripeSetupIntentForm from '../UserStripeSetupIntentForm/UserStripeSetupIntentForm'
import {getStripePublicKey} from '../../../../../config/env'
import {OrderStatus} from '../../../../../models/order/OrderStatus'


const options = {
  mode: 'setup',
  currency: 'usd',
  appearance: {},
} as StripeElementsOptions

interface UserStripeSetupIntentProps {
  orderStatus: OrderStatus
  mutateOrder: () => void
}

export default function UserStripeSetupIntent({orderStatus, mutateOrder}: UserStripeSetupIntentProps) {

  return <>
    <Elements stripe={loadStripe(getStripePublicKey() || '')} options={options}>
      <UserStripeSetupIntentForm orderStatus={orderStatus} mutateOrder={mutateOrder} />
    </Elements>
  </>
}
