import './style.css'
import { BaseOrder } from '../../../../models/order/BaseOrder'
import Text_ from '../../../elements/./Text/Text'
import Divider_ from '../../../elements/Divider/Divider'

interface OrderCardRouteInfoProps {
  order: BaseOrder
}

export default function OrderCardRouteInfo({ order }: OrderCardRouteInfoProps) {
  return <>
    <Divider_ text='Route info' className='order_card__divider__route_info' />
    <Text_ label='Distance, mi' value={order.distance_data.distance_mi} className='order_card__distance_mi' />
    <Text_ label='Duration, sec' value={order.distance_data.duration_seconds} className='order_card__duration_seconds' />
  </>
}
