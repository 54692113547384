import {Route, Routes, useLocation} from 'react-router-dom'
import {
  ABOUT_US__PATH,
  ACCOUNT_ONBOARDING__PATH,
  ADMIN__AGREEMENT_CARD__PATH,
  ADMIN__AGREEMENT_CREATE__PATH,
  ADMIN__AGREEMENTS__PATH,
  ADMIN__DASHBOARD__PATH,
  ADMIN__ORDER_CARD__PATH,
  ADMIN__ORDERS__PATH,
  ADMIN__RATE_CARD__PATH,
  ADMIN__RATES__PATH,
  ADMIN__RATES_CREATE__PATH,
  ADMIN__ROUTE_CARD__PATH,
  ADMIN__ROUTES__PATH,
  ADMIN__USER_CARD__PATH,
  ADMIN__USERS__PATH,
  ADMIN__VISITS__PATH,
  BECOME_A_DRIVER,
  DRIVER__AGREEMENT_CARD__PATH,
  DRIVER__AGREEMENTS__PATH,
  DRIVER__DASHBOARD__PATH,
  DRIVER__ORDER_CARD__PATH,
  DRIVER__ORDERS__PATH,
  DRIVER__ROUTE_CARD__PATH,
  DRIVER__ROUTE_CREATE__PATH,
  DRIVER__ROUTES__PATH,
  DRIVER__STRIPE__PATH,
  HELP__PATH,
  LOGIN__PATH,
  LOGIN_REDIRECT__PATH,
  LOGIN_WITH_OTP__PATH,
  LOGOUT__PATH,
  MAIN__PATH,
  ORDER_TRACK_CARD__PATH,
  ORDER_TRACKS__PATH,
  PAGE_403__PATH,
  PROFILE__PATH,
  USER__AGREEMENT_CARD__PATH,
  USER__AGREEMENTS__PATH,
  USER__ORDER_CARD__PATH,
  USER__ORDER_CREATE_CONTINUE_V2__PATH,
  USER__ORDER_CREATE_V2__PATH,
  USER__ORDERS__PATH
} from './config/page_paths'
import AboutUsPage from './pages/./AboutUs/AboutUsPage'
import NotFoundPage from './pages/NotFound/NotFoundPage'
import DriverRouteCreatePage from './pages/route/DriverRouteCreatePage/DriverRouteCreatePage'
import DriverRouteCardPage from './pages/route/DriverRouteCardPage/DriverRouteCardPage'
import DriverRoutesPage from './pages/route/DriverRoutesPage/DriverRoutesPage'
import AdminRoutesPage from './pages/route/AdminRoutesPage/AdminRoutesPage'
import AdminRouteCardPage from './pages/route/AdminRouteCardPage/AdminRouteCardPage'
import AdminVisitsPage from './pages/visit/AdminVisitsPage/AdminVisitsPage'
import AdminRateCardPage from './pages/rate/AdminRateCardPage/AdminRateCardPage'
import AdminRatesPage from './pages/rate/AdminRatesPage/AdminRatesPage'
import AdminRateCreatePage from './pages/rate/AdminRateCreatePage/AdminRateCreatePage'
import UserProfilePage from './pages/user/UserProfilePage/UserProfilePage'
import AdminUsersPage from './pages/user/AdminUsersPage/AdminUsersPage'
import AdminUserCardPage from './pages/user/AdminUserCardPage/AdminUserCardPage'
import UserOrderCardPage from './pages/order/UserOrderCardPage/UserOrderCardPage'
import OrderTrackCardPage from './pages/order/OrderTrackCardPage/OrderTrackCardPage'
import AdminOrdersPage from './pages/order/AdminOrdersPage/AdminOrdersPage'
import AdminOrderCardPage from './pages/order/AdminOrderCardPage/AdminOrderCardPage'
import MainPage from './pages/./Main/MainPage'
import DriverDashboardPage from './pages/dashboard/DriverDashboardPage/DriverDashboardPage'
import DriverOrderCardPage from './pages/order/DriverOrderCardPage/DriverOrderCardPage'
import DriverOrdersPage from './pages/order/DriverOrdersPage/DriverOrdersPage'
import UserOrdersPage from './pages/order/UserOrdersPage/UserOrdersPage'
import AdminDashboardPage from './pages/dashboard/AdminDashboardPage/AdminDashboardPage'
import Layout from './pages/Layout/Layout'
import LoginPage from './pages/auth/Login/LoginPage'
import AuthOtpPage from './pages/auth/./AuthOtp/AuthOtpPage'
import AuthRedirectPage from './pages/auth/AuthRedirectPage'
import LogoutPage from './pages/auth/LogoutPage'
import HelpPage from './pages/Help/HelpPage'
import OrderTrackSearchPage from './pages/order/OrderTrackSearchPage/OrderTrackSearchPage'
import UnauthorizedPage from './pages/Unauthorized/UnauthorizedPage'
import UserAccountOnboardingPage from './pages/user/UserAccountOnboardingPage/UserAccountOnboardingPage'
import BecomeADriverPage from './pages/./BecomeADriver/BecomeADriverPage'
import DriverStripePage from './pages/user/DriverStripePage/DriverStripePage'
import AdminAgreementCardPage from './pages/agreement/admin/AdminAgreementCardPage/AdminAgreementCardPage'
import AdminAgreementsPage from './pages/agreement/admin/AdminAgreementsPage/AdminAgreementsPage'
import DriverAgreementsPage from './pages/agreement/driver/DriverAgreementsPage/DriverAgreementsPage'
import DriverAgreementCardPage from './pages/agreement/driver/DriverAgreementCardPage/DriverAgreementCardPage'
import AdminAgreementCreatePage from './pages/agreement/admin/AdminAgreementCreatePage/AdminAgreementCreatePage'
import UserAgreementCardPage from './pages/agreement/user/UserAgreementCardPage/UserAgreementCardPage'
import UserAgreementsPage from './pages/agreement/user/UserAgreementsPage/UserAgreementsPage'
import {useLayoutEffect} from 'react'
import UserOrderCreateV2Page from './pages/order/UserOrderCreateV2Page/UserOrderCreateV2Page'
import {Helmet} from "react-helmet"
import {getSeoKeywords} from "./config/env";

export default function App() {
  const location = useLocation()
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return <>
    <Layout>
      <Routes>
        {/* DASHBOARDS */}
        <Route path={MAIN__PATH} element={<MainPage />} />
        <Route path={DRIVER__DASHBOARD__PATH} element={<DriverDashboardPage />} />
        <Route path={ADMIN__DASHBOARD__PATH} element={<AdminDashboardPage />} />


        {/* AGREEMENTS */}
        <Route path={USER__AGREEMENT_CARD__PATH} element={<UserAgreementCardPage />} />
        <Route path={USER__AGREEMENTS__PATH} element={<UserAgreementsPage />} />

        <Route path={DRIVER__AGREEMENT_CARD__PATH} element={<DriverAgreementCardPage />} />
        <Route path={DRIVER__AGREEMENTS__PATH} element={<DriverAgreementsPage />} />

        <Route path={ADMIN__AGREEMENT_CREATE__PATH} element={<AdminAgreementCreatePage />} />
        <Route path={ADMIN__AGREEMENT_CARD__PATH} element={<AdminAgreementCardPage />} />
        <Route path={ADMIN__AGREEMENTS__PATH} element={<AdminAgreementsPage />} />


        {/*ORDERS */}
        <Route path={ORDER_TRACK_CARD__PATH} element={<OrderTrackCardPage />} />
        <Route path={ORDER_TRACKS__PATH} element={<OrderTrackSearchPage />} />

        {/*<Route path={USER__ORDER_CREATE__PATH} element={<UserOrderCreatePage />} />*/}
        <Route path={USER__ORDER_CREATE_V2__PATH} element={<UserOrderCreateV2Page />} />
        <Route path={USER__ORDER_CREATE_CONTINUE_V2__PATH} element={<UserOrderCreateV2Page />} />
        <Route path={USER__ORDER_CARD__PATH} element={<UserOrderCardPage />} />
        <Route path={USER__ORDERS__PATH} element={<UserOrdersPage />} />

        <Route path={DRIVER__ORDER_CARD__PATH} element={<DriverOrderCardPage />} />
        <Route path={DRIVER__ORDERS__PATH} element={<DriverOrdersPage />} />

        <Route path={ADMIN__ORDER_CARD__PATH} element={<AdminOrderCardPage />} />
        <Route path={ADMIN__ORDERS__PATH} element={<AdminOrdersPage />} />


        {/* ROUTES */}
        <Route path={DRIVER__ROUTE_CREATE__PATH} element={<DriverRouteCreatePage />} />
        <Route path={DRIVER__ROUTE_CARD__PATH} element={<DriverRouteCardPage />} />
        <Route path={DRIVER__ROUTES__PATH} element={<DriverRoutesPage />} />

        <Route path={ADMIN__ROUTE_CARD__PATH} element={<AdminRouteCardPage />} />
        <Route path={ADMIN__ROUTES__PATH} element={<AdminRoutesPage />} />


        {/* RATES */}
        <Route path={ADMIN__RATES_CREATE__PATH} element={<AdminRateCreatePage />} />
        <Route path={ADMIN__RATE_CARD__PATH} element={<AdminRateCardPage />} />
        <Route path={ADMIN__RATES__PATH} element={<AdminRatesPage />} />


        {/* USERS */}
        <Route path={PROFILE__PATH} element={<UserProfilePage />} />
        <Route path={BECOME_A_DRIVER} element={<BecomeADriverPage />} />
        <Route path={ADMIN__USER_CARD__PATH} element={<AdminUserCardPage />} />
        <Route path={ADMIN__USERS__PATH} element={<AdminUsersPage />} />
        <Route path={DRIVER__STRIPE__PATH} element={<DriverStripePage />} />


        {/*VISITS*/}
        <Route path={ADMIN__VISITS__PATH} element={<AdminVisitsPage />} />


        {/*HELP*/}
        <Route path={HELP__PATH} element={<HelpPage />} />


        {/* AUTH */}
        <Route path={ACCOUNT_ONBOARDING__PATH} element={<UserAccountOnboardingPage />} />
        <Route path={LOGIN__PATH} element={<LoginPage />} />
        <Route path={LOGIN_WITH_OTP__PATH} element={<AuthOtpPage />} />
        <Route path={LOGIN_REDIRECT__PATH} element={<AuthRedirectPage />} />
        <Route path={LOGOUT__PATH} element={<LogoutPage />} />


        {/* STATIC PAGES */}
        <Route path={ABOUT_US__PATH} element={<AboutUsPage />} />
        <Route path={PAGE_403__PATH} element={<UnauthorizedPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Layout>
    <div>
      <Helmet>
        {getSeoKeywords() && <meta name="keywords" content={getSeoKeywords()} />}
      </Helmet>
    </div>
  </>
}
