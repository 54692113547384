import './style.css'
import { Typography } from '@mui/material'
import { useDebugState } from 'use-named-state'
import { OrderStatus } from '../../../../models/order/OrderStatus'
import Button_ from '../../../elements/Button/Button'
import FileInput, { FileInputType } from '../../../elements/./FileInput/FileInput'
import Text_ from '../../../elements/./Text/Text'
import { adminOrderDeliver, adminOrderMarkAsPaymentHeld, adminOrderPickup } from '../../../../api/order/admin_order_api'

interface AdminOrderActionsProps {
  mutateOrder?: () => void
  orderId: string
  orderStatus: OrderStatus
}

export function AdminOrderActions({ mutateOrder, orderId, orderStatus }: AdminOrderActionsProps) {
  const [files, setFiles] = useDebugState<FileList | undefined>('files', undefined)
  const [internalComment, setInternalComment] = useDebugState<string | undefined>('internalComment', undefined)

  function cleanUp() {
    setFiles(undefined)
    setInternalComment(undefined)
  }

  function buildActions(orderStatus: OrderStatus) {
    if (orderStatus == OrderStatus.ACCEPTED) {
      return <>
        <Button_ label='Mark as payment was held' onClick={() => adminOrderMarkAsPaymentHeld(orderId).then(() => mutateOrder && mutateOrder())} logPath={'AdminOrderMarkAsPaidButton.adminOrderMarkAsPaymentHeld'} />
      </>
    } else if (orderStatus == OrderStatus.ASSIGNED) {
      let lambda: (() => Promise<any>) | undefined = undefined
      if (files) {
        lambda = () => adminOrderPickup(orderId, { internal_comment: internalComment }, files)
          .then(() => {
            cleanUp()
            mutateOrder && mutateOrder()
          })
      }
      return <>
        <Typography>Attach pickup photos:</Typography>
        <FileInput label='Upload Photos' type={FileInputType.IMAGE} files={files} onChange={setFiles} />
        <Text_ label='Internal comment' value={internalComment} onChange={setInternalComment} />
        <Button_ label='Pick up an Order' disabled={!files} onClick={lambda} logPath='AdminOrderActions.buildActions.adminOrderPickup' />
      </>
    } else if (orderStatus == OrderStatus.PICKED_UP) {
      let lambda: (() => Promise<any>) | undefined = undefined
      if (files) {
        lambda = () => adminOrderDeliver(orderId, { internal_comment: internalComment }, files)
          .then(() => {
            cleanUp()
            mutateOrder && mutateOrder()
          })
      }
      return <>
        <Typography>Attach deliver photos:</Typography>
        <FileInput label='Upload Photos' type={FileInputType.IMAGE} files={files} onChange={setFiles} />
        <Text_ label='Internal comment' value={internalComment} onChange={setInternalComment} />
        <Button_ label='Deliver an Order' disabled={!files} onClick={lambda} logPath='AdminOrderActions.buildActions.adminOrderDeliver' />
      </>
    } else {
      return <></>
    }
  }

  return <div className='admin_order_actions'>
    {buildActions(orderStatus)}
  </div>
}
