import { UserDriverStatus } from '../models/user/UserDriverStatus'
import { DRIVER_STATUS_EMPTY_VALUE } from '../components/user/admin/AdminUserDriverStatus/AdminUserDriverStatus'

const REQUESTED_STATUS = 'Requested'
const NOT_INTERESTED_STATUS = 'Not interested'
const ACCEPTED_STATUS = 'Accepted'
const REJECTED_STATUS = 'Rejected'

export function toUiUserDriverStatus(userDriverStatus?: UserDriverStatus): string {
  switch (userDriverStatus) {
    case undefined:
      return DRIVER_STATUS_EMPTY_VALUE
    case UserDriverStatus.REQUESTED:
      return REQUESTED_STATUS
    case UserDriverStatus.NOT_INTERESTED:
      return NOT_INTERESTED_STATUS
    case UserDriverStatus.ACCEPTED:
      return ACCEPTED_STATUS
    case UserDriverStatus.REJECTED:
      return REJECTED_STATUS
  }
}

export function fromUiUserDriverStatus(uiUserDriverStatus?: string): UserDriverStatus | undefined {
  switch (uiUserDriverStatus) {
    case undefined:
      return undefined
    case DRIVER_STATUS_EMPTY_VALUE:
      return undefined
    case REQUESTED_STATUS:
      return UserDriverStatus.REQUESTED
    case NOT_INTERESTED_STATUS:
      return UserDriverStatus.NOT_INTERESTED
    case ACCEPTED_STATUS:
      return UserDriverStatus.ACCEPTED
    case REJECTED_STATUS:
      return UserDriverStatus.REJECTED
  }
}
