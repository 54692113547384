import './style.css'
import { useParams } from 'react-router-dom'
import UserAgreementCard from '../../../../components/agreement/user/UserAgreementCard/UserAgreementCard'
import UserTab, { UserTabConfig } from '../../../../components/tab/UserTab/UserTab'

export default function UserAgreementCardPage() {
  const { agreementId } = useParams()
  return <div className='user_agreement_card_page'>
    <UserTab tabIndex={UserTabConfig.Agreements.index} />
    <UserAgreementCard agreementId={agreementId as string} />
  </div>
}
