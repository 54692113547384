import './style.css'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useDebugState } from 'use-named-state'
import { useEffect } from 'react'
import Text_ from '../../../elements/Text/Text'
import { meUpdate } from '../../../../api/user/user_api'
import { useUser } from '../../../../pages/Layout/Layout'
import Tel from '../../../elements/Tel/Tel'

export default function UserRequestMissingInfo() {
  const { user, mutateUser } = useUser()
  const [open, setOpen] = useDebugState('open', true)
  const [name, setName] = useDebugState<string | undefined>('name', undefined)
  const [phoneNumber, setPhoneNumber] = useDebugState<string | undefined>('phoneNumber', undefined)

  useEffect(() => {
    setName(user?.name)
    setPhoneNumber(user?.phone_number)
  }, [user])

  function isChanged() {
    return user?.name !== name
      || user?.phone_number !== phoneNumber
  }

  const handleClose = () => {
    if (isChanged()) {
      meUpdate({
          name: name,
          phone_number: phoneNumber,
        }
      ).then(() => {
        mutateUser && mutateUser()
        setOpen(false)
      })
    } else {
      console.log('ExtraInfoDialog.handleClose.isChanged')
    }
  }

  return <>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>User Profile</DialogTitle>
      <DialogContent className='user_request_missing_info'>
        <DialogContentText className='user_request_missing_info__message'>Please provide required information:</DialogContentText>
        {!user?.name && <Text_ label='Name' value={name} onChange={setName} className='user_request_missing_info__name' />}
        <Tel label='Phone number *' value={phoneNumber} onChange={setPhoneNumber} className='user_request_missing_info__phone_number' />
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} disabled={!isChanged()} onClick={handleClose}>Save</Button>
      </DialogActions>
    </Dialog>
  </>
}
