import './style.css'
import { useAdminRoute } from '../../../../api/route/admin_route_api'
import RouteCardBasicInfo from '../../card_info/RouteCardBasicInfo/RouteCardBasicInfo'
import RouteCardRouteInfo from '../../card_info/RouteCardRouteInfo/RouteCardRouteInfo'
import RouteCardStopsDetails from '../../card_info/RouteCardStopsDetails/RouteCardStopsDetails'
import { CircularProgress } from '@mui/material'
import Divider_ from '../../../elements/Divider/Divider'

interface AdminRouteCardProps {
  routeId: string
}

export default function AdminRouteCard({ routeId }: AdminRouteCardProps) {
  const { data: route } = useAdminRoute(routeId)
  return <>
    {route ? <div className='admin_route_card__fields'>
        <Divider_ text={`Route overview: ${routeId}`} className='admin_route_card__divider__overview' />
        <RouteCardRouteInfo route={route} />
        <RouteCardStopsDetails route={route} />
        <RouteCardBasicInfo route={route} />
      </div>
      : <CircularProgress />
    }
  </>
}
