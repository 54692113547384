import './style.css'
import Text_ from '../../../elements/./Text/Text'
import { UserOrder } from '../../../../models/order/UserOrder'
import { AdminOrder } from '../../../../models/order/AdminOrder'
import Divider_ from '../../../elements/Divider/Divider'

interface OrderCardPaymentInfoProps {
  order: UserOrder | AdminOrder
}

export default function OrderCardPaymentInfo({ order }: OrderCardPaymentInfoProps) {
  return <>
    <Divider_ text='Payment info' className='order_card__divider__payment_info' />
    <Text_ label='Stripe Customer ID' value={order.stripe_data?.customer_id} className='order_card__customer_id' />
    <Text_ label='Stripe Payment Method ID' value={order.stripe_data?.payment_method_id} className='order_card__payment_method_id' />
    <Text_ label='Stripe Payment Intent ID' value={order.stripe_data?.payment_intent_id} className='order_card__payment_intent_id' />

  </>
}
