import './style.css'
import UserDashboardPage from '../dashboard/UserDashboardPage/UserDashboardPage'
import AdminDashboardPage from '../dashboard/AdminDashboardPage/AdminDashboardPage'
import DriverDashboardPage from '../dashboard/DriverDashboardPage/DriverDashboardPage'
import { useUser } from '../Layout/Layout'
import { UserRole } from '../../models/user/UserRole'
import UserOrderCreateV2Page from "../order/UserOrderCreateV2Page/UserOrderCreateV2Page";

export default function MainPage() {
  const { user } = useUser()

  const chooseMainPage = (userRole?: UserRole, userSelectedRole?: UserRole) => {
    const role = userSelectedRole || userRole
    if (role === undefined) {
      return <UserOrderCreateV2Page />
    } else if (role === UserRole.USER) {
      return <UserDashboardPage />
    } else if (role === UserRole.ADMIN) {
      return <AdminDashboardPage />
    } else if (role === UserRole.DRIVER) {
      return <DriverDashboardPage />
    }
  }

  return <>
    {chooseMainPage(user?.role, user?.selected_role)}
  </>
}
