import './style.css'
import DriverTab, { DriverTabConfig } from '../../../components/tab/DriverTab/DriverTab'
import Typography from '@mui/material/Typography'

export default function DriverStripePage() {
  return <div className='driver_stripe_page'>
    <DriverTab tabIndex={DriverTabConfig.Stripe.index} />
    <Typography className='driver_stripe_page__description'>
       Login to <a href='https://connect.stripe.com/express_login' target='_blank'>Stripe</a> account to get more details
    </Typography>
  </div>
}
