export enum PageState {
  Address,
  Package,
  PickupAndDeliveryDetails,
  PhotosDecision,
  PhotosUploadAndProceed,
  Login,
  PaymentSetup,
  ConfirmAndAgreement,
  Done,
}
